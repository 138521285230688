import {Typography, TypographyVariants} from '../../atomic'
import useTabs from './hook'
import {Tab, TabContent, TabsContainer} from './styles'
import {TabsViewProps} from './types'

const TabView = (props: TabsViewProps) => {
  const {tabs} = props

  const {activeIndex, handleTabClick} = useTabs()

  return (
    <>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            isActive={activeIndex === index}
            onClick={() => handleTabClick(index)}
          >
            <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
              {tab.name}
            </Typography>
          </Tab>
        ))}
      </TabsContainer>
      <TabContent>
        {tabs.map((tab, index) => {
          const Component = tab.component
          return activeIndex === index ? (
            <Component isActive={activeIndex === index} key={index} />
          ) : null
        })}
      </TabContent>
    </>
  )
}

export default TabView
