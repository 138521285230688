import {Typography, TypographyVariants} from '../../atomic'
import useConsultRatingReason from './hook'
import {StyledConsultRatingReason, StyledReasonOption} from './styles'
import {ConsultRatingReasonProps} from '@mosaic-wellness/fe-types'

function ConsultRatingReason(props: ConsultRatingReasonProps) {
  const {title = '', options = []} = props || {}

  const {onSuggestionSelect, selectedOptions} = useConsultRatingReason(props)

  return (
    <StyledConsultRatingReason>
      <Typography
        variant={TypographyVariants.CTA_LABEL_SMALL}
        customClassName="title"
      >
        {title}
      </Typography>
      <div className="divider" />
      <div className="options-wrapper">
        {options.map((option: string, index: number) => (
          <StyledReasonOption
            isSelected={selectedOptions.includes(option)}
            key={`rating-reason-${index}`}
            onClick={onSuggestionSelect(option)}
          >
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="label"
            >
              {option}
            </Typography>
          </StyledReasonOption>
        ))}
      </div>
    </StyledConsultRatingReason>
  )
}

export default ConsultRatingReason
