import styled from 'styled-components'

const StyledHSpacer = styled.div`
  &[data-variant='xx-small'] {
    width: 2px;
  }
  &[data-variant='x-small'] {
    width: 4px;
  }
  &[data-variant='small'] {
    width: 8px;
  }
  &[data-variant='medium'] {
    width: 12px;
  }
  &[data-variant='large'] {
    width: 16px;
  }
  &[data-variant='x-large'] {
    width: 24px;
  }
  &[data-variant='xx-large'] {
    width: 32px;
  }
`

export {StyledHSpacer}
