import {
  OptimizedImage,
  Spacer,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {StyledConsultRatingConsultationDetails} from './styles'
import {ConsultRatingConsultationDetailsProps} from '@mosaic-wellness/fe-types'

function ConsultRatingConsultationDetails(
  props: ConsultRatingConsultationDetailsProps
) {
  const {
    consultationId = '',
    name = '',
    subTitle = '',
    avatar = '',
  } = props || {}

  return (
    <StyledConsultRatingConsultationDetails>
      <div>
        <div className="consult-id">
          <Typography
            variant={TypographyVariants.X_SMALL}
            customClassName="label"
          >
            Consultation ID: {consultationId}
          </Typography>
        </div>
        <Spacer variant="small" />
        <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
          {name}
        </Typography>
        <Typography
          variant={TypographyVariants.X_SMALL}
          customClassName="label"
        >
          {subTitle}
        </Typography>
      </div>
      <div className="doctor-image-wrapper">
        <OptimizedImage
          source={avatar}
          customClassName="doctor-image"
          data-testid="doctor-avatar"
        />
      </div>
    </StyledConsultRatingConsultationDetails>
  )
}

export default ConsultRatingConsultationDetails
