import React from 'react'
import {StyledLoader} from './styles'
function LoadingState({
  numberOfCards = 1,
  cardHeight = 20,
  cardWidth = '100%',
  customClassName = '',
}) {
  return (
    <StyledLoader
      className={`loading-container ${customClassName}`}
      cardHeight={cardHeight}
      cardWidth={cardWidth}
    >
      {Array(numberOfCards)
        .fill(1)
        .map((val, index) => {
          return <div className="loading-card shimmer" key={index}></div>
        })}
    </StyledLoader>
  )
}

export default LoadingState
