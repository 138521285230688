import React, {useCallback, useEffect} from 'react'

import VideoRender from './VideoPlayer'

interface OptimizedVideoProps {
  state?: {
    videoComponentClassName?: string
    autoplay?: boolean
    muted?: boolean
    loop?: boolean
    playsInline?: boolean
    controls?: boolean
    preload?: 'none' | 'metadata' | 'auto'
    height?: number | string
    width?: number | string
    additionalClassNames?: string
    loading?: 'auto' | 'lazy'
    source?: string
    type?: string
    desktopViewWidth?: string
    mobileViewWidth?: string
    posterImage?: string
    disablePictureInPicture?: boolean
    playIcon?: string
    borderRadius?: number
    customStyle?: React.CSSProperties
  }
}

function OptimizedVideo(props: OptimizedVideoProps) {
  const {state = {}} = props || {}
  const {videoComponentClassName = 'video-render-component', autoplay = false} =
    state

  const playPauseVideo = useCallback(() => {
    const videos = document.querySelectorAll(`.${videoComponentClassName}`)
    videos.forEach((video) => {
      const videoElement = video as HTMLVideoElement // Cast to ensure video properties
      const playPromise = videoElement.play()
      if (playPromise !== undefined) {
        playPromise.then((_) => {
          const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (!entry.isIntersecting && !videoElement.paused) {
                  videoElement.pause()
                } else if (
                  entry.isIntersecting &&
                  videoElement.paused &&
                  !videoElement.ended &&
                  autoplay
                ) {
                  videoElement.play()
                }
              })
            },
            {threshold: 0.2}
          )
          observer.observe(video)
        })
      }
    })
  }, [autoplay, videoComponentClassName])

  useEffect(() => {
    if (document.readyState === 'complete' && autoplay) {
      playPauseVideo()
    } else if (autoplay) {
      window.addEventListener('load', playPauseVideo)
      return () => document.removeEventListener('load', playPauseVideo)
    }
  }, [autoplay, playPauseVideo, videoComponentClassName])

  return (
    <VideoRender
      state={{additionalClassNames: videoComponentClassName, ...state}}
      actions={{playPauseVideo}}
    />
  )
}

export default OptimizedVideo
