import noop from 'lodash/noop'
import {StyledContainer} from './styles'
import {TagsProps} from './types'
import {Close, Typography, TypographyVariants} from '../../../atomic'

const Tags = (props: TagsProps) => {
  const {tags = [], handleSelection = noop} = props || {}

  const onTagClick = (tag: string) => {
    handleSelection({
      label: tag,
      value: tag,
    })
  }

  return (
    <StyledContainer>
      {tags.map((tag: string, index: number) => (
        <div
          key={`selected_tags_${index}`}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => onTagClick(tag)}
          className="tag"
        >
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="label"
          >
            {tag}
          </Typography>
          <Close height={16} width={16} stroke="#669F84" />
        </div>
      ))}
    </StyledContainer>
  )
}

export default Tags
