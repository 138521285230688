import styled from 'styled-components'

export const StyledConsultRatingConsultationDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--dls-size-8);
  padding: var(--dls-size-16);
  padding-top: 12px;
  border-radius: var(--dls-size-16);
  background: var(--brand-primary-white-color);

  .consult-id {
    background: var(--brand-light-bg-color);
    padding: var(--dls-size-2) var(--dls-size-4);
    border-radius: var(--dls-size-2);
    display: inline-block;
  }

  .label {
    color: var(--brand-light-secondary-text-color);
  }

  .doctor-image-wrapper {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    overflow: hidden;
  }

  .doctor-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`
