import {
  Close,
  DropdownList,
  Spacer,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {StyledContainer, InputContainer} from './styles'
import {LabelSearchInputWithTagsProps} from './types'
import Tags from './Tags'
import useLabelSearchInputWithTags from './hook'

const LabelSearchInputWithTags = (props: LabelSearchInputWithTagsProps) => {
  const {label = '', placeholder = '', isMandatory = false} = props || {}

  const {
    inputValue,
    selectedSuggestions,
    onChangeText,
    filteredSuggestions,
    onSuggestionSelect,
    onSubmitEditingOrBlur,
    onClose,
    handleInputKeyDown,
    onFocus,
  } = useLabelSearchInputWithTags(props)

  return (
    <StyledContainer>
      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="label"
      >
        {label}
        {isMandatory && '*'}
      </Typography>
      <InputContainer showCloseIcon={!!inputValue}>
        <input
          type="text"
          value={inputValue}
          onChange={onChangeText}
          placeholder={placeholder}
          onBlur={onSubmitEditingOrBlur}
          onKeyDown={handleInputKeyDown}
          onFocus={onFocus}
        />
        <div
          className="closeIcon"
          onMouseDown={(e) => e.preventDefault()}
          onClick={onClose}
        >
          <Close />
        </div>
        <DropdownList
          selectedSuggestions={selectedSuggestions}
          suggestions={filteredSuggestions}
          handleSelection={onSuggestionSelect}
          multiSelect={true}
        />
      </InputContainer>
      <Spacer variant="medium" />
      <Tags tags={selectedSuggestions} handleSelection={onSuggestionSelect} />
    </StyledContainer>
  )
}

export default LabelSearchInputWithTags
