import styled from 'styled-components'

export const StyledRxSectionHeaderWrapper = styled.div`
  padding: 3px 10px;
  background: linear-gradient(
    90deg,
    var(--brand-light-bg-color) 0%,
    var(--brand-primary-white-color) 100%
  );
  .title {
    color: var(--brand-light-secondary-text-color);
  }
`
