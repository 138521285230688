import {LanguageListProps} from './types'
import {LanguageOption} from './types'
import {StyledLanguageList} from './styles'
import useLanguageListSelection from './useLanguageListSelection'
import LanguageListItem from './LanguageListItem'

const LanguageList = (props: LanguageListProps) => {
  const {listItems = []} = props || {}
  const {selectedLanguage, handleLanguageSelect} =
    useLanguageListSelection(props)

  return (
    <StyledLanguageList>
      {listItems.map((item: LanguageOption, index: number) => (
        <LanguageListItem
          isSelected={selectedLanguage === item.value}
          onOptionSelect={handleLanguageSelect(item)}
          {...item}
          key={'language_item_' + index}
        />
      ))}
    </StyledLanguageList>
  )
}

export default LanguageList
