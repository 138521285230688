export const imageWidthMappings = {
  desktop: {
    FULL: 1080,
    HALF: 800,
    THIRD: 500,
    SMALL: 150,
    TINY: 50,
  },
  mobile: {
    FULL: 800,
    HALF: 500,
    THIRD: 300,
    SMALL: 150,
    TINY: 50,
  },
}

export function getOptimizedImageKitLink(
  source = '',
  desktopViewWidth = 'FULL',
  mobileViewWidth = 'FULL',
  isMobile = true
) {
  try {
    if (source) {
      const imageKitWidth = isMobile
        ? imageWidthMappings.mobile[
            mobileViewWidth as keyof typeof imageWidthMappings.mobile
          ]
        : imageWidthMappings.desktop[
            desktopViewWidth as keyof typeof imageWidthMappings.desktop
          ]
      const url = new URL(source)
      const hasMWParam = url.searchParams.get('mw') === '1'
      if (!hasMWParam) url.searchParams.set('tr', `w-${imageKitWidth}`)
      return `${url.href},c-at_max`
    }
    return ''
  } catch (e) {
    return ''
  }
}
