const Alert = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="alert-triangle">
        <path
          id="Icon"
          d="M6.99988 5.74989V8.08322M6.99988 10.4166H7.00571M6.19215 2.77006L1.3943 11.0573C1.12818 11.5169 0.995117 11.7467 1.01478 11.9354C1.03194 12.0999 1.11814 12.2494 1.25193 12.3467C1.40532 12.4582 1.67089 12.4582 2.20203 12.4582H11.7977C12.3289 12.4582 12.5944 12.4582 12.7478 12.3467C12.8816 12.2494 12.9678 12.0999 12.985 11.9354C13.0046 11.7467 12.8716 11.5169 12.6055 11.0573L7.80761 2.77006C7.54244 2.31205 7.40986 2.08304 7.23689 2.00613C7.086 1.93904 6.91375 1.93904 6.76287 2.00613C6.58989 2.08304 6.45731 2.31205 6.19215 2.77006Z"
          stroke="#DF555B"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default Alert
