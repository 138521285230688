import React from 'react'
import {
  GenericSliderWrapper,
  SliderControls,
  GenericSliderContainer,
} from './styles'
import {IGenericSliderProps} from './types'
import {useDeviceTypeContext} from '../../../contexts/DeviceType'
import {RightArrow} from '@web-components'
import {LeftArrow} from '@web-components'
import {useGenericSlider} from './useGenericSlider'

function GenericSlider(props: IGenericSliderProps) {
  const {
    noOfSlides = 1,
    noOfSlidesDesktop,
    textAlign = 'left',
    aspectRatio = 1,
    startFromSlide = 0,
    onSlideChange = () => null,
    children,
    slideGap = 8,
    arrowPositionY = 325,
    LeftArrowIcon = LeftArrow,
    RightArrowIcon = RightArrow,
    arrowGap = 960,
    isShowArrows = false,
  } = props
  const {isMobile} = useDeviceTypeContext()
  const {
    sliderRef,
    noOfSlidesUsed,
    currentSlide,
    isEndOfScroll,
    imageDimensions,
    handleNextClick,
    handlePrevClick,
  } = useGenericSlider({
    noOfSlides,
    noOfSlidesDesktop,
    aspectRatio,
    startFromSlide,
    onSlideChange,
    isMobile,
  })

  return (
    <>
      <GenericSliderWrapper>
        <GenericSliderContainer
          ref={sliderRef} // Attach the ref to the slider container
          noOfSlides={noOfSlidesUsed}
          slidesTextAlign={textAlign}
          startFromSlide={startFromSlide}
          className="generic-slider"
          slideGap={slideGap}
        >
          {children({imageDimensions})}
        </GenericSliderContainer>

        {isShowArrows && (
          <SliderControls
            arrowPositionY={arrowPositionY}
            arrowGap={arrowGap}
            slideGap={slideGap}
          >
            <button
              className={currentSlide > 0 ? 'arrow-visible' : 'arrow-hidden'}
              onClick={handlePrevClick}
            >
              <LeftArrowIcon />
            </button>

            <button
              className={!isEndOfScroll ? 'arrow-visible' : 'arrow-hidden'}
              onClick={handleNextClick}
            >
              <RightArrowIcon />
            </button>
          </SliderControls>
        )}
      </GenericSliderWrapper>
    </>
  )
}

export default GenericSlider
