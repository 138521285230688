import {StyledExpandableSection} from './styles'
import useExpandableSection from './hook'
import {DropdownArrow, Typography, TypographyVariants} from '../../atomic'
import {ExpandableSectionProps} from '@mosaic-wellness/fe-types'
import React from 'react'

interface ExpandableSectionUpdatedProps extends ExpandableSectionProps {
  variant?: TypographyVariants
}
function ExpandableSection(props: ExpandableSectionUpdatedProps) {
  const {
    title,
    children,
    isCollapsible = true,
    wrapperClassName = '',
    variant = TypographyVariants.BODY_BASE_REGULAR,
    customTitleClassName = '',
    headerClassName = '',
    customSubTitleClassName = '',
    subTitle = '',
    subTitleAmountColor = '',
  } = props || {}

  const {isListOpen, handleToggleList} = useExpandableSection(props)

  return (
    <StyledExpandableSection
      className={`wrapper ${wrapperClassName}`}
      isCollapsible={isCollapsible}
      subTitleAmountColor={subTitleAmountColor}
    >
      <div className={`header ${headerClassName}`} onClick={handleToggleList}>
        <Typography
          variant={variant}
          customClassName={`title ${customTitleClassName}`}
        >
          {title}
        </Typography>
        <div className="subTitleContainer">
          <Typography
            variant={variant}
            customClassName={`subtitle ${customSubTitleClassName}`}
          >
            {subTitle}
          </Typography>
          {isCollapsible && (
            <DropdownArrow
              stroke="var(--brand-primary-text-color)"
              isClosed={!isListOpen}
            />
          )}
        </div>
      </div>
      {isListOpen ? children : null}
    </StyledExpandableSection>
  )
}

export default React.memo(ExpandableSection)
