const CheckBox = ({
  width = 18,
  height = 18,
  isChecked = true,
  fill = 'var(--brand-primary-color)',
  checkboxBorder = 'var(--brand-light-secondary-text-color)',
}) => {
  const fillColor = isChecked ? fill : 'transparent'
  const borderColor = isChecked ? fill : checkboxBorder
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1564_4928)" strokeWidth={1.5} id="arrow-left">
        <rect
          x={3.75}
          y={3.75}
          width={16.5}
          height={16.5}
          rx={3.25}
          fill={fillColor}
          stroke={borderColor}
        />
        <path
          d="M8 12.6l2.449 2.4 2.633-3 2.632-3"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default CheckBox
