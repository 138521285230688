import styled from 'styled-components'
import {
  GenericCarouselStyleProps,
  CarouselStyleProps,
  ContainerProps,
  SlideControlsProps,
} from './types'

export const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: ${(props) => (props.enableOverflow ? 'visible' : 'hidden')};
`

export const TransitionSlides = styled.div`
  display: flex;
  height: 100%;
  transition: transform 0.7s ease-out;
`

export const NormalSlides = styled.div`
  display: flex;
  height: 100%;
`

export const Slide = styled.div<GenericCarouselStyleProps>`
  min-width: ${(props) => 100 / props.slidesToShow}%;
  position: relative;
  padding: 0 ${(props) => props.slideGap}px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`

export const SlideControls = styled.div<SlideControlsProps>`
  position: absolute;
  top: ${(props) => props.arrowPositionY}px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${(props) => props.arrowGap}px;
  align-items: center;
  left: 0;
  right: 0;
  button {
    cursor: pointer;
    background: transparent;
    font-size: 30px;
    border: none;
    padding: 10px;
    color: white;
    outline: none !important;
    box-shadow: none !important;

    &:focus {
      outline: none !important;
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      padding: 0;
      cursor: pointer;
    }
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }
`

export const SlideContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  font-size: 60px;
  color: white;
`

export const DotContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  align-items: center;
`

export const Dot = styled.span<CarouselStyleProps>`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  background-color: ${({'data-active': active}) =>
    active ? 'var(--category-primary-color)' : '#ccc'};
  border-radius: 50%;
  display: inline-block;
  @media (max-width: 768px) {
    width: ${({'data-active': active}) => (active ? '15px' : '10px')};
    height: ${({'data-active': active}) => (active ? '15px' : '10px')};
  }
`
