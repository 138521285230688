import styled from 'styled-components'

export const TextRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .warning-text {
    color: var(--dls-error-color-500);
  }
  .secondary-text {
    color: var(--brand-light-secondary-text-color);
  }
  .subText {
    width: 80%;
    a {
      margin-left: var(--dls-size-8);
      color: var(--dls-brand-primary-color-500);
    }
  }
`
