import styled from 'styled-components'

const StyledSelectInputMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    margin-bottom: var(--dls-size-8);
  }
  .secondary {
    color: var(--brand-light-secondary-text-color);
  }
`

const StyledSelectInput = styled.select`
  height: 48px;
  border: 1px solid var(--dls-divider-light-color);
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  padding: 0 16px;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 24px;

  &:focus {
    border: 1px solid var(--dls-divider-light-color);
  }
`

export {StyledSelectInput, StyledSelectInputMain}
