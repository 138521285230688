import {forwardRef} from 'react'
import {HEADING_TAGS_MAPPING} from '../../../constants'
import {TypographyWrapper} from './styles'
import {ITypography, TypographyVariants} from './types'

const Typography = forwardRef(function (props: ITypography, ref) {
  const {
    variant = TypographyVariants.BODY_BASE_REGULAR,
    customClassName = '',
    headingLevel,
    children = '',
    testId = '',
    style = {},
    ...rest
  } = props

  return (
    <TypographyWrapper
      className={`${variant} ${customClassName}`}
      as={
        headingLevel ? HEADING_TAGS_MAPPING[headingLevel ?? 1] : ('span' as any)
      }
      data-testid={testId}
      style={style}
      ref={ref}
      {...rest}
    >
      {children}
    </TypographyWrapper>
  )
})

export default Typography
