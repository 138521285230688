import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {IProductCardProps} from './ProductCard.interface'
import {ProductCardContainer} from './ProductCard.styles'
import {useProductCard} from '../../../hooks'

function ProductCard(props: IProductCardProps) {
  const {product} = props
  const {
    alttext = '',
    discountedPriceLabel = '',
    name = '',
    priceLabel = '',
    rating = '',
    reviewCount = '',
    whyThisWorks,
    ctaLabel = '',
  } = product || {}

  const [
    {imageToRender, showWhyThisWorks, showDiscountedPrice},
    {handleCtaClick, handleCardClick},
  ] = useProductCard({product})

  return (
    <ProductCardContainer onClick={handleCardClick}>
      <div className="img-container">
        <OptimizedImage
          source={imageToRender}
          aspectWidth="155"
          aspectHeight="155"
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText={alttext || name}
          customClassName="product-image"
        />
      </div>

      <div className="product-info">
        {rating ? (
          <div className="rating-container">
            <ReviewRatingStar count={5} value={Number(rating)} size={12} />
            <div className="ratinginfo">
              {Number(rating) > 0 && (
                <Typography variant={TypographyVariants.X_SMALL}>
                  {rating}
                </Typography>
              )}
              {parseFloat(reviewCount) > 0 && (
                <>
                  <Typography variant={TypographyVariants.X_SMALL}>
                    | ({reviewCount})
                  </Typography>
                  <OptimizedImage
                    source="https://i.mscwlns.co/mosaic-wellness/image/upload/v1657884668/Little%20Joys/PDP/tick.png"
                    aspectWidth="12"
                    aspectHeight="12"
                    desktopViewWidth="TINY"
                    mobileViewWidth="TINY"
                    altText="verified-icon"
                    loading="lazy"
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="alternate-rating"></div>
        )}
        <div className="product-name">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {name}
          </Typography>
        </div>
        <div className="pricing">
          {showDiscountedPrice ? (
            <div className="price-ctn">
              <div>
                <Typography
                  variant={TypographyVariants.BODY_BASE_BOLD}
                  customClassName="price"
                >
                  {discountedPriceLabel}
                </Typography>
              </div>

              <div>
                <Typography
                  variant={TypographyVariants.BODY_BASE_REGULAR}
                  customClassName="discounted-price"
                >
                  {priceLabel}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="price-top-container">
              <Typography
                variant={TypographyVariants.BODY_BASE_BOLD}
                customClassName="price price-ctn"
              >
                {priceLabel}
              </Typography>
            </div>
          )}

          {!!showWhyThisWorks && (
            <div className="whyThisWorks">
              {whyThisWorks?.map(({label, text}) => {
                if (!text || !label) return null
                return (
                  <div key={label} className="why-this-work-item">
                    <Typography
                      variant={TypographyVariants.X_SMALL}
                      customClassName="key"
                    >
                      {label}
                    </Typography>
                    <Typography variant={TypographyVariants.X_SMALL}>
                      {text}
                    </Typography>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>

      <button
        className="atc-btn category-primary-atc-btn"
        data-location="product-card"
        onClick={handleCtaClick}
      >
        <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
          {ctaLabel}
        </Typography>
      </button>
    </ProductCardContainer>
  )
}

export default ProductCard
