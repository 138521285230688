import styled from 'styled-components'
export const WalletTransactionCardContainer = styled.div<{
  amountColor: string
  subtitle2Color: string
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .image_and_text_container {
    display: flex;
    gap: var(--dls-size-8);
    justify-content: center;
    align-items: center;

    .image_container {
      border-radius: 50%;
      background-color: var(--dls-brand-secondary-color-200);
      padding: var(--dls-size-8);
      border: var(--dls-size-1) solid var(--dls-divider-light-color);
      width: var(--dls-size-40);
      height: var(--dls-size-40);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text_container {
      display: flex;
      flex-direction: column;
      gap: var(--dls-size-4);

      .subtitle_text_container {
        display: flex;
        flex-direction: row;
        gap: var(--dls-size-8);
        align-items: center;

        .subtitle_pill {
          padding: var(--dls-size-4) var(--dls-size-8);
          border-radius: var(--dls-size-16);
          border: var(--dls-size-1) solid var(--dls-divider-light-color);
          background-color: var(--dls-brand-primary-color-100);

          .subtitle_pill_text {
            color: var(--dls-brand-primary-color-500);
          }
        }
      }

      .subtitle_and_subtitle2_container {
        display: flex;
        flex-direction: row;
        gap: var(--dls-size-4);
        align-items: center;

        .dot_and_pill_text {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: var(--dls-size-4);

          .dot {
            height: var(--dls-size-4);
            width: var(--dls-size-4);
            border-radius: 50%;
            background-color: ${({subtitle2Color}) =>
              subtitle2Color || `var(--dls-primary-text-colors)`};
          }

          .dot_text {
            color: ${({subtitle2Color}) =>
              subtitle2Color || `var(--brand-light-secondary-text-color)`};
          }
        }
      }
    }
  }

  .text {
    color: var(--brand-light-secondary-text-color);
  }

  .amount_container {
    .amount_text {
      color: ${({amountColor}) =>
        amountColor || `var(--dls-primary-text-colors)`};
    }
  }
`
