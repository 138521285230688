import {useState} from 'react'

const useTabs = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleTabClick = (index: number) => {
    setActiveIndex(index)
  }

  return {
    activeIndex,
    handleTabClick,
  }
}

export default useTabs
