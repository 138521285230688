import React, {useEffect} from 'react'
import {IWalletTransactionTabList} from './types'
import {StyleContainer} from './styles'
import BannerWithTextAndCta from '../BannerWithTextAndCta'
import TimeLine from './TimeLine'
import noop from 'lodash/noop'
import EmptyWallet from './EmptyWallet'

const WalletTransactionTabContent = (props: IWalletTransactionTabList) => {
  const {state, actions} = props || {}
  const {tab, isTransactionLoading = false, loadMoreCta} = state || {}
  const {
    banner,
    timeline = [],
    showEmptyBanner = false,
    emptyBanner,
  } = tab || {}
  const {
    handleTabChange = noop,
    onLoadTransactions = noop,
    triggerClickOnBannerCta = noop,
    handleClickOnBannerCta = noop,
  } = actions || {}

  useEffect(() => {
    if (handleTabChange) {
      handleTabChange(tab.type)
    }
  }, [handleTabChange, tab.type])

  return (
    <StyleContainer>
      <div className="banner">
        <BannerWithTextAndCta
          state={{
            banner,
          }}
          actions={{
            triggerClickOnBannerCta,
            handleClickOnBannerCta,
          }}
        />
      </div>
      <div className="accordion_container">
        {showEmptyBanner ? (
          <EmptyWallet state={{emptyBanner}} />
        ) : (
          timeline?.map((timeline) => (
            <TimeLine
              key={timeline.title}
              timeline={timeline}
              state={{
                timeline,
                isTransactionLoading,
                loadMoreCta,
              }}
              actions={{
                onLoadTransactions,
              }}
            />
          ))
        )}
      </div>
    </StyleContainer>
  )
}

export default WalletTransactionTabContent
