import {ExpandableSectionProps} from '@mosaic-wellness/fe-types'
import noop from 'lodash/noop'
import {useState} from 'react'

const useExpandableSection = (props: ExpandableSectionProps) => {
  const {
    isCollapsible = true,
    openByDefault = false,
    onListToggle = noop,
  } = props
  const [isListOpen, setIsListOpen] = useState<boolean>(
    !isCollapsible ? true : openByDefault
  )

  const handleToggleList = () => {
    if (!isCollapsible) {
      return
    }
    onListToggle({
      sectionName: props?.title,
      action: isListOpen ? 'close' : 'open',
    })
    setIsListOpen((prev) => !prev)
  }

  return {
    isListOpen,
    handleToggleList,
  }
}

export default useExpandableSection
