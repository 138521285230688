import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {IProductCardProps} from './ProductCardV2.interface'
import {ProductCardV2Container} from './ProductCardV2.styles'
import {useProductCard} from '../../../hooks'

function ProductCardV2(props: IProductCardProps) {
  const {product} = props
  const {
    alttext = '',
    discountedPriceLabel = '',
    name = '',
    priceLabel = '',
    rating = '',
    reviewCount = '',
    whyThisWorks,
    ctaLabel = '',
    slugUrl = '',
  } = product || {}

  const [
    {imageToRender, showWhyThisWorks, showDiscountedPrice},
    {handleCtaClick, handleCardClick},
  ] = useProductCard({product})

  return (
    <ProductCardV2Container onClick={handleCardClick} href={slugUrl}>
      <div className="img-container">
        <OptimizedImage
          source={imageToRender}
          aspectWidth="160"
          aspectHeight="120"
          desktopViewWidth="HALF"
          mobileViewWidth="HALF"
          altText={alttext || name}
          customClassName="product-image"
        />
      </div>

      <div className="product-info">
        {rating ? (
          <div className="rating-container">
            <ReviewRatingStar count={1} value={Number(rating)} size={12} />
            <div className="ratinginfo">
              {Number(rating) > 0 && (
                <Typography variant={TypographyVariants.X_SMALL}>
                  {rating}
                </Typography>
              )}
              {parseFloat(reviewCount) > 0 && (
                <>
                  <Typography
                    variant={TypographyVariants.X_SMALL}
                    customClassName="review-count"
                  >
                    | {reviewCount} reviews
                  </Typography>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="alternate-rating"></div>
        )}
        <div className="product-name">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {name}
          </Typography>
        </div>
        <div className="pricing">
          {showDiscountedPrice ? (
            <div className="price-ctn">
              <div>
                <Typography
                  variant={TypographyVariants.BODY_BASE_BOLD}
                  customClassName="price"
                >
                  {discountedPriceLabel}
                </Typography>
              </div>

              <div>
                <Typography
                  variant={TypographyVariants.BODY_BASE_REGULAR}
                  customClassName="discounted-price"
                >
                  {priceLabel}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="price-top-container">
              <Typography
                variant={TypographyVariants.BODY_BASE_BOLD}
                customClassName="price price-ctn"
              >
                {priceLabel}
              </Typography>
            </div>
          )}

          {!!showWhyThisWorks && (
            <div className="whyThisWorks">
              {whyThisWorks?.map(({label, text}) => {
                if (!text || !label) return null
                return (
                  <div key={label} className="why-this-work-item">
                    <Typography
                      variant={TypographyVariants.X_SMALL}
                      customClassName="key"
                    >
                      {label}
                    </Typography>
                    <Typography
                      variant={TypographyVariants.X_SMALL}
                      customClassName="text"
                    >
                      {text}
                    </Typography>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>

      <div className="button-container">
        <button
          className="atc-btn"
          data-location="product-card"
          onClick={handleCtaClick}
        >
          <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
            {ctaLabel}
          </Typography>
        </button>
      </div>
    </ProductCardV2Container>
  )
}

export default ProductCardV2
