import React from 'react'
import {
  TransactionSummaryContainer,
  WalletTransactionSummaryContainer,
} from './styles'
import {OptimizedImage, Typography, TypographyVariants} from '../../atomic'
import {IWalletTransactionSummaryProps} from './types'
import isEmpty from 'lodash/isEmpty'

const WalletTransactionSummaryCard = (
  props: IWalletTransactionSummaryProps
) => {
  const {currentWalletData = []} = props || {}

  if (isEmpty(currentWalletData)) {
    return null
  }

  return (
    <WalletTransactionSummaryContainer>
      {currentWalletData.map((walletData, index) => {
        const {
          icon = '',
          title = '',
          amount = '',
          amountSubtitle = '',
          amountColor = '#212121',
        } = walletData || {}

        return (
          <>
            <TransactionSummaryContainer amountColor={amountColor}>
              <div className="transaction_header">
                <OptimizedImage
                  source={icon}
                  aspectWidth={20}
                  aspectHeight={20}
                />
                <Typography
                  variant={TypographyVariants.BODY_BASE_REGULAR}
                  customClassName="text"
                >
                  {title}
                </Typography>
              </div>
              <div className="wallet_summary_text_container">
                <Typography
                  variant={TypographyVariants.HEADING_MEDIUM_BOLD}
                  customClassName="amount"
                >
                  {amount}
                </Typography>
                <Typography
                  variant={TypographyVariants.TAG_REGULAR}
                  customClassName="amount"
                >
                  {amountSubtitle}
                </Typography>
              </div>
            </TransactionSummaryContainer>
            {index !== currentWalletData.length - 1 && (
              <span className="divider" />
            )}
          </>
        )
      })}
    </WalletTransactionSummaryContainer>
  )
}

export default WalletTransactionSummaryCard
