export enum TypographyVariants {
  X_SMALL = 'x-small',
  TAG_REGULAR = 'tag-regular',
  TAG_BOLD = 'tag-bold',
  CTA_LABEL_SMALL = 'cta-label-sm',
  CTA_LABEL_BIG = 'cta-label-lg',
  BODY_BASE_LIGHT = 'body-base-light',
  BODY_BASE_REGULAR = 'body-base-regular',
  BODY_BASE_BOLD = 'body-base-bold',
  SUBHEADING_LIGHT = 'subheading-light',
  SUBHEADING_REGULAR = 'subheading-regular',
  SUBHEADING_BOLD = 'subheading-bold',
  HEADING_SMALL_REGULAR = 'heading-sm-regular',
  HEADING_SMALL_BOLD = 'heading-sm-bold',
  HEADING_MEDIUM_REGULAR = 'heading-md-regular',
  HEADING_MEDIUM_BOLD = 'heading-md-bold',
  HEADING_LARGE_REGULAR = 'heading-lg-regular',
  HEADING_LARGE_BOLD = 'heading-lg-bold',
  HEADING_XLARGE_REGULAR = 'heading-xl-regular',
  HEADING_XLARGE_BOLD = 'heading-xl-bold',
  HEADING_2XLARGE_REGULAR = 'heading-2xl-regular',
  HEADING_2XLARGE_BOLD = 'heading-2xl-bold',
  HEADING_3XLARGE_REGULAR = 'heading-3xl-regular',
  HEADING_3XLARGE_BOLD = 'heading-3xl-bold',
}

type THeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

export interface ITypography {
  variant?: TypographyVariants
  customClassName?: string
  headingLevel?: THeadingLevel
  children?: any
  testId?: string
  style?: any
}
