import styled from 'styled-components'

export const CompletionScreenWrapper = styled.div`
  background-color: var(--dls-white-color);
  padding: var(--dls-size-16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .Titletext {
    text-align: center;
  }
  .subTitletext {
    text-align: center;
    width: var(--dls-size-288);
    color: var(--dls-secondary-text-colors);
  }
`
