const FileGreen = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="file-05">
        <path
          id="Icon"
          d="M33.8334 5.48486V15.467C33.8334 16.8205 33.8334 17.4972 34.0968 18.0141C34.3284 18.4689 34.6981 18.8386 35.1529 19.0703C35.6698 19.3337 36.3466 19.3337 37.7 19.3337H47.6822M33.8334 41.0835H19.3334M38.6667 31.4168H19.3334M48.3334 24.1384V41.5668C48.3334 45.6272 48.3334 47.6574 47.5431 49.2083C46.8481 50.5724 45.739 51.6815 44.3748 52.3766C42.8239 53.1668 40.7937 53.1668 36.7334 53.1668H21.2667C17.2063 53.1668 15.1761 53.1668 13.6253 52.3766C12.2611 51.6815 11.152 50.5724 10.4569 49.2083C9.66669 47.6574 9.66669 45.6272 9.66669 41.5668V16.4335C9.66669 12.3731 9.66669 10.3429 10.4569 8.79207C11.152 7.42789 12.2611 6.31878 13.6253 5.6237C15.1761 4.8335 17.2063 4.8335 21.2667 4.8335H29.0285C30.8018 4.8335 31.6884 4.8335 32.5228 5.03381C33.2625 5.21142 33.9697 5.50435 34.6184 5.90185C35.3501 6.35021 35.977 6.97716 37.2309 8.23106L44.9358 15.9359C46.1897 17.1898 46.8166 17.8168 47.265 18.5484C47.6625 19.1971 47.9554 19.9043 48.133 20.6441C48.3334 21.4785 48.3334 22.3651 48.3334 24.1384Z"
          stroke="#2F815C"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default FileGreen
