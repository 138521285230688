import styled from 'styled-components'
import {SliderControlsType} from './types'

export const GenericSliderWrapper = styled.div<{
  slideGap?: number
  slidesTextAlign?: string
  noOfSlides?: number
}>`
  position: relative;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  .container {
    width: 100%;
    display: flex;
    /* TODO: type the props */
    gap: ${(props) => props.slideGap}px;

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      text-align: ${({slidesTextAlign = 'left'}) => slidesTextAlign};
      ${({noOfSlides}) => {
        const slideValue = `calc((100% - ${
          16 + Math.floor(noOfSlides) * 8
        }px)/${noOfSlides})`

        return `width: ${slideValue};
    flex: 0 0 ${slideValue};
    `
      }};

      scroll-snap-align: start; /* Ensure each slide snaps into place */
      scroll-margin-left: ${(startFromSlide) =>
        startFromSlide ? '20px' : '0px'};
      scroll-behavior: smooth;
      :first-child {
        margin-left: 16px;
      }

      :last-child {
        margin-right: 16px;
      }
    }
  }
`

export const SliderControls = styled.div<SliderControlsType>`
  width: 100%;
  display: flex;
  gap: ${(props) => props.arrowGap}px;
  justify-content: center;
  position: absolute;
  top: ${(props) => props.arrowPositionY}px;
  left: ${(props) =>
    props.slideGap / 2 - 2}px; // to center align the arrow based on slide width
  right: 0px;

  button {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none !important;
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      padding: 0;
      cursor: pointer;
    }
  }

  svg {
    cursor: pointer;
  }

  .arrow-visible {
    visibility: visible;
  }

  .arrow-hidden {
    visibility: hidden;
  }
`
export const GenericSliderContainer = styled.div<{
  slidesTextAlign: string
  noOfSlides: number
  startFromSlide: number
  slideGap: number
}>`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  gap: ${(props) => props.slideGap}px;
  -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    text-align: ${({slidesTextAlign = 'left'}) => slidesTextAlign};
    ${({noOfSlides}) => {
      const slideValue = `calc((100% - ${
        16 + Math.floor(noOfSlides) * 8
      }px)/${noOfSlides})`
      return `width: ${slideValue};
    flex: 0 0 ${slideValue};
    `
    }};
    scroll-snap-align: start; /* Ensure each slide snaps into place */
    scroll-margin-left: ${(startFromSlide) =>
      startFromSlide ? '20px' : '0px'};
    scroll-behavior: smooth;
    :first-child {
      margin-left: 16px;
    }
    :last-child {
      margin-right: 16px;
    }
  }
`
