const Delete = ({width = 16, height = 17, stroke = '#DF555B'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M6 2.5H10M2 4.5H14M12.6667 4.5L12.1991 11.5129C12.129 12.565 12.0939 13.0911 11.8667 13.49C11.6666 13.8412 11.3648 14.1235 11.0011 14.2998C10.588 14.5 10.0607 14.5 9.00623 14.5H6.99377C5.93927 14.5 5.41202 14.5 4.99889 14.2998C4.63517 14.1235 4.33339 13.8412 4.13332 13.49C3.90607 13.0911 3.871 12.565 3.80086 11.5129L3.33333 4.5M6.66667 7.5V10.8333M9.33333 7.5V10.8333"
        stroke={stroke}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Delete
