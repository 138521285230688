import {Spacer, Typography, TypographyVariants} from '../../atomic'
import ImageOrAnimationBanner from '../ImageOrAnimationBanner'
import {CompletionScreenWrapper} from './styles'
import {CompletionScreenProps} from './types'

const CompletionScreen = (props: CompletionScreenProps) => {
  const {title = '', subTitle = '', animationWidth = '104'} = props || {}

  return (
    <CompletionScreenWrapper>
      <ImageOrAnimationBanner {...props} width={animationWidth} />
      <Spacer variant="x-large" />

      <Typography
        customClassName="Titletext"
        variant={TypographyVariants.BODY_BASE_BOLD}
      >
        {title}
      </Typography>
      <Spacer variant="small" />
      <Typography
        customClassName="subTitletext"
        variant={TypographyVariants.TAG_REGULAR}
      >
        {subTitle}
      </Typography>
    </CompletionScreenWrapper>
  )
}

export default CompletionScreen
