import styled from 'styled-components'

export const RxDoctorSignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  .secondary-text {
    color: var(--brand-light-secondary-text-color);
  }
  .sign {
    max-width: 100px;
    max-height: 50px;
  }
`
