import styled from 'styled-components'

export const TypographyWrapper = styled.span`
  margin: 0;
  font-family: var(--dls-brand-font-family);
  line-height: var(--dls-line-height);

  &.x-small {
    font-size: 10px;
    font-weight: 400;
  }

  &.tag-regular {
    font-size: 12px;
    font-weight: 400;
  }

  &.tag-bold {
    font-size: 12px;
    font-weight: 700;
  }

  &.cta-label-sm {
    font-size: 14px;
    font-weight: 600;
  }

  &.cta-label-lg {
    font-size: 16px;
    font-weight: 600;
  }

  &.body-base-regular {
    font-size: 14px;
    font-weight: 400;
  }

  &.body-base-light {
    font-size: 14px;
    font-weight: 300;
  }

  &.body-base-bold {
    font-size: 14px;
    font-weight: 700;
  }

  &.subheading-regular {
    font-size: 16px;
    font-weight: 400;
  }

  &.subheading-light {
    font-size: 16px;
    font-weight: 300;
  }

  &.subheading-bold {
    font-size: 16px;
    font-weight: 700;
  }

  &.heading-sm-regular {
    font-size: 20px;
    font-weight: 400;
  }

  &.heading-sm-bold {
    font-size: 20px;
    font-weight: 700;
  }

  &.heading-md-regular {
    font-size: 24px;
    font-weight: 400;
  }

  &.heading-md-bold {
    font-size: 24px;
    font-weight: 700;
  }

  &.heading-lg-regular {
    font-size: 28px;
    font-weight: 400;
  }

  &.heading-lg-bold {
    font-size: 28px;
    font-weight: 700;
  }

  &.heading-xl-regular {
    font-size: 36px;
    font-weight: 400;
  }

  &.heading-xl-bold {
    font-size: 36px;
    font-weight: 700;
  }

  &.heading-2xl-regular {
    font-size: 42px;
    font-weight: 400;
  }

  &.heading-2xl-bold {
    font-size: 42px;
    font-weight: 700;
  }

  &.heading-3xl-regular {
    font-size: 50px;
    font-weight: 400;
  }

  &.heading-3xl-bold {
    font-size: 50px;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    &.x-small {
      font-size: 14px;
    }

    &.tag-regular {
      font-size: 16px;
    }

    &.tag-bold {
      font-size: 14px;
    }

    &.cta-label-sm {
      font-size: 18px;
    }

    &.cta-label-lg {
      font-size: 20px;
    }

    &.body-base-regular {
      font-size: 18px;
    }

    &.body-base-light {
      font-size: 18px;
    }

    &.body-base-bold {
      font-size: 18px;
    }

    &.subheading-regular {
      font-size: 22px;
    }

    &.subheading-light {
      font-size: 22px;
    }

    &.subheading-bold {
      font-size: 22px;
    }

    &.heading-sm-regular {
      font-size: 26px;
    }

    &.heading-sm-bold {
      font-size: 26px;
    }

    &.heading-md-regular {
      font-size: 32px;
    }

    &.heading-md-bold {
      font-size: 32px;
    }

    &.heading-lg-regular {
      font-size: 36px;
    }

    &.heading-lg-bold {
      font-size: 36px;
    }

    &.heading-xl-regular {
      font-size: 38px;
    }

    &.heading-xl-bold {
      font-size: 38px;
    }

    &.heading-2xl-regular {
      font-size: 54px;
    }

    &.heading-2xl-bold {
      font-size: 54px;
    }

    &.heading-3xl-regular {
      font-size: 64px;
    }

    &.heading-3xl-bold {
      font-size: 64px;
    }
  }
`
