import styled, {css} from 'styled-components'

export const StyledLanguageListItem = styled.div<{fullWidth: boolean}>`
  border-radius: var(--dls-size-8);
  border: var(--dls-size-1) solid var(--brand-primary-light-divider-color);
  padding: 20px;
  cursor: pointer;
  text-align: center;
  ${(props) =>
    props.fullWidth
      ? css`
          width: 100%;
        `
      : css`
          flex: 0 1 calc(50% - var(--dls-size-4));
        `}

  &.selected-language {
    border-color: var(--dls-brand-primary-color-500);
    color: var(--dls-brand-primary-color-500);
    background-color: var(--dls-brand-primary-color-100);
  }
`
