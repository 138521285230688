import {TypographyVariants} from '../../atomic'
import {ExpandableSection} from '../../molecules'
import {ConsultRatingAdditionalInfoWrapper, InputWrapper} from './styles'
import {DoctorReviewProps} from './types'
import useInputHandler from './hook'

const ConsultRatingAdditionalInfo = (props: DoctorReviewProps) => {
  const {title = '', placeholder = ''} = props || {}
  const {inputVal, isFocused, handleFocus, handleBlur, handleTextChange} =
    useInputHandler(props)
  return (
    <ConsultRatingAdditionalInfoWrapper>
      <ExpandableSection
        variant={TypographyVariants.CTA_LABEL_SMALL}
        customTitleClassName="titleWrapper"
        title={title}
        wrapperClassName="headerWrapper"
        children={
          <InputWrapper isFocused={isFocused}>
            <div className="contentWrapper">
              <textarea
                value={inputVal}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleTextChange}
                rows={4}
                placeholder={placeholder}
              />
            </div>
          </InputWrapper>
        }
      />
    </ConsultRatingAdditionalInfoWrapper>
  )
}
export default ConsultRatingAdditionalInfo
