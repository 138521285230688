import * as React from 'react'

const MinusIcon = ({fill = 'var(--dls-brand-primary-color-500)', ...props}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 9V7H12V9H4Z" fill={fill} />
  </svg>
)

export default MinusIcon
