import {StyledRatingStarSelector} from './styles'
import {OptimizedImage, RatingStar} from '../../atomic'
import {RatingStarSelectorProps} from '@mosaic-wellness/fe-types'
import ImageOrAnimationBanner from '../ImageOrAnimationBanner'

function RatingStarSelector(props: RatingStarSelectorProps) {
  const {
    rating = 0,
    totalRating = 5,
    onRatingSelect,
    selectedStarIcon = '',
    width = 40,
    height = 40,
    gap = 'var(--dls-size-8)',
    selectedStarIconProps,
  } = props || {}

  return (
    <StyledRatingStarSelector gap={gap}>
      {Array.from({length: totalRating}, (_, index) => {
        const starIndex = index + 1
        const fillColor = rating >= starIndex ? '#F6CA4C' : 'none'
        const strokeColor =
          rating >= starIndex ? 'none' : 'var(--dls-disabled-text-colors)'
        if (
          (selectedStarIcon || selectedStarIconProps) &&
          rating === starIndex
        ) {
          if (selectedStarIconProps) {
            return (
              <ImageOrAnimationBanner
                key={'star' + index}
                width={`${width}px`}
                {...selectedStarIconProps}
              />
            )
          }
          return (
            <OptimizedImage
              key={'star' + index}
              source={selectedStarIcon}
              aspectWidth={width}
              aspectHeight={height}
              data-testid="custom-selected-star-img"
            />
          )
        }
        return (
          <div
            key={'rating_star_' + index}
            data-testid="star-icon"
            className="star"
            onClick={onRatingSelect && onRatingSelect(starIndex)}
          >
            <RatingStar
              width={width}
              height={height}
              stroke={strokeColor}
              fill={fillColor}
            />
          </div>
        )
      })}
    </StyledRatingStarSelector>
  )
}

export default RatingStarSelector
