import noop from 'lodash/noop'
import React, {useCallback, useMemo, useState} from 'react'

import {getOptimizedImageKitLink} from '../OptimizedImage/getOptimizedImageLink'
import {StyledWrapper} from './VideoPlayer.styles'

interface VideoPlayerProps {
  state?: {
    autoplay?: boolean
    muted?: boolean
    loop?: boolean
    playsInline?: boolean
    controls?: boolean
    preload?: 'none' | 'metadata' | 'auto'
    height?: number | string
    width?: number | string
    additionalClassNames?: string
    videoComponentClassName?: string
    loading?: 'auto' | 'lazy'
    source?: string
    type?: string
    desktopViewWidth?: string
    mobileViewWidth?: string
    posterImage?: string
    disablePictureInPicture?: boolean
    playIcon?: string
    customStyle?: React.CSSProperties
  }
  actions?: {
    playPauseVideo?: (source?: string) => void // Optional argument for source
  }
}

function VideoPlayer(props: VideoPlayerProps) {
  const {state = {}, actions = {}} = props || {}
  const {
    autoplay = false,
    muted = true,
    loop = false,
    playsInline = false,
    controls = false,
    preload = 'metadata',
    additionalClassNames = '',
    videoComponentClassName = '',
    source = '',
    type = 'video/mp4',
    desktopViewWidth = 'FULL',
    mobileViewWidth = 'HALF',
    posterImage = '',
    disablePictureInPicture = true,
    playIcon = '',
    width,
    height,
  } = state

  const {playPauseVideo = noop} = actions

  const [isVideoPlaying, setIsVideoPlaying] = useState(autoplay)

  const modifiedSrc = useMemo(() => {
    return getOptimizedImageKitLink(source, desktopViewWidth, mobileViewWidth)
  }, [source, desktopViewWidth, mobileViewWidth])

  const showControls = useMemo(() => {
    return isVideoPlaying && controls
  }, [controls, isVideoPlaying])

  const onOverlayClick = useCallback(
    (e: any) => {
      e.preventDefault()
      const videos = document.querySelectorAll(`.${additionalClassNames}`)
      videos.forEach((video) => {
        const videoElement = video as HTMLVideoElement
        if (videoElement.paused) {
          playPauseVideo()
          setIsVideoPlaying(true)
        } else {
          videoElement.pause()
          setIsVideoPlaying(false)
        }
      })
    },
    [additionalClassNames, playPauseVideo]
  )

  return (
    <StyledWrapper>
      <video
        autoPlay={autoplay}
        muted={muted}
        loop={loop}
        playsInline={playsInline}
        preload={preload}
        className={`video ${additionalClassNames} ${videoComponentClassName}`}
        poster={posterImage}
        controls={showControls}
        controlsList="nodownload noremoteplayback noplaybackrate foobar"
        disablePictureInPicture={disablePictureInPicture}
        onPlay={() => setIsVideoPlaying(true)}
        onPause={() => setIsVideoPlaying(false)}
        width={width}
        height={height}
      >
        <source id="my-video" src={modifiedSrc} type={type} />
      </video>
      {playIcon && controls && (
        <div className="video-overlay" onClick={onOverlayClick}>
          <div className="video-label-btn-container">
            <img src={isVideoPlaying ? '' : playIcon} alt="" />
          </div>
        </div>
      )}
    </StyledWrapper>
  )
}

export default VideoPlayer
