import {
  Button,
  Close,
  LabelSearchInputWithTags,
  Spacer,
  Typography,
  TypographyVariants,
} from '@web-components'
import {
  StyledConfirmationModalContainer,
  StyledConfirmationModal,
  StyledFooterCTAs,
} from './styles'
import {ConfirmationModalWIthInputProps} from './types'
import noop from 'lodash/noop'

const ConfirmationModalWIthInput = (props: ConfirmationModalWIthInputProps) => {
  const {
    title = '',
    subTitle = '',
    footerCta = [],
    showClose = true,
    isInputEnabled = false,
    inputData,
    onClose = noop,
  } = props

  return (
    <StyledConfirmationModalContainer>
      <StyledConfirmationModal>
        <div className="header">
          <Typography variant={TypographyVariants.SUBHEADING_BOLD}>
            {title}
          </Typography>
          {showClose ? (
            <button className="closeIcon" onClick={onClose}>
              <Close />
            </button>
          ) : null}
        </div>
        {subTitle ? (
          <>
            <Spacer variant="x-small" />
            <Typography
              variant={TypographyVariants.BODY_BASE_REGULAR}
              customClassName="secondary"
            >
              {subTitle}
            </Typography>
          </>
        ) : null}
        {isInputEnabled && inputData && (
          <>
            <Spacer variant="large" />
            <LabelSearchInputWithTags {...inputData} />
          </>
        )}
        <Spacer variant="large" />
        <StyledFooterCTAs>
          {footerCta.map((cta, index) => (
            <Button
              {...cta}
              key={'footer_cta_' + index}
              customLabelClassName="ctaLabel"
            ></Button>
          ))}
        </StyledFooterCTAs>
      </StyledConfirmationModal>
    </StyledConfirmationModalContainer>
  )
}

export default ConfirmationModalWIthInput
