import {useRef, useState} from 'react'
import {SearchDropdownProps} from './types'
import {DropdownOption} from '@mosaic-wellness/fe-types'
import noop from 'lodash/noop'

const useSearchDropdown = (props: SearchDropdownProps) => {
  const [isListOpen, setIsListOpen] = useState(false)
  const doctorInputRef = useRef<HTMLInputElement>(null)
  const {dropdownData} = props || {}
  const {handleSelection = noop} = dropdownData || {}

  const handleCloseList = () => {
    setIsListOpen(false)
    if (doctorInputRef.current) {
      doctorInputRef.current.blur()
    }
  }

  const handleOpenList = () => {
    setIsListOpen(true)
    if (doctorInputRef.current) {
      doctorInputRef.current.focus()
    }
  }

  const handleListSelection = (data: DropdownOption) => {
    handleSelection(data)
    handleCloseList()
  }

  return {
    isListOpen,
    doctorInputRef,
    handleCloseList,
    handleOpenList,
    handleListSelection,
  }
}

export default useSearchDropdown
