import {ConsultRatingReasonProps} from '@mosaic-wellness/fe-types'
import {useCallback, useState} from 'react'

const useConsultRatingReason = (props: ConsultRatingReasonProps) => {
  const {handleSelectedOptions, selectedOptions: sSuggestions = []} = props

  const [selectedOptions, setSelectedOptions] = useState<string[]>(sSuggestions)

  const onSuggestionSelect = useCallback(
    (option: string) => () => {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((selectedOption) => selectedOption !== option)
        : [...selectedOptions, option]

      setSelectedOptions(newSelectedOptions)
      handleSelectedOptions(newSelectedOptions)
    },
    [selectedOptions, handleSelectedOptions]
  )

  return {
    selectedOptions,
    onSuggestionSelect,
  }
}

export default useConsultRatingReason
