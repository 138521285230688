const AudioCallActive = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#DF555B" />
      <path
        d="M22.0497 14C23.0264 14.1906 23.924 14.6683 24.6277 15.3719C25.3314 16.0756 25.8091 16.9733 25.9997 17.95M22.0497 10C24.0789 10.2254 25.9713 11.1342 27.4159 12.577C28.8606 14.0198 29.7717 15.911 29.9997 17.94M18.2266 21.8631C17.0251 20.6615 16.0763 19.3028 15.3803 17.8532C15.3204 17.7285 15.2905 17.6662 15.2675 17.5873C15.1858 17.3069 15.2445 16.9627 15.4145 16.7253C15.4623 16.6584 15.5195 16.6013 15.6338 16.487C15.9834 16.1374 16.1582 15.9626 16.2725 15.7868C16.7035 15.1239 16.7035 14.2693 16.2725 13.6064C16.1582 13.4307 15.9834 13.2559 15.6338 12.9062L15.4389 12.7114C14.9075 12.1799 14.6417 11.9142 14.3564 11.7699C13.7888 11.4828 13.1185 11.4828 12.551 11.7699C12.2656 11.9142 11.9999 12.1799 11.4684 12.7114L11.3108 12.869C10.7812 13.3986 10.5164 13.6634 10.3141 14.0235C10.0897 14.423 9.92833 15.0435 9.9297 15.5017C9.93092 15.9146 10.011 16.1969 10.1712 16.7613C11.0322 19.7947 12.6567 22.6571 15.0447 25.045C17.4326 27.433 20.295 29.0575 23.3284 29.9185C23.8928 30.0787 24.1751 30.1588 24.588 30.16C25.0462 30.1614 25.6667 30 26.0662 29.7756C26.4263 29.5733 26.6911 29.3085 27.2207 28.7789L27.3783 28.6213C27.9098 28.0898 28.1755 27.8241 28.3198 27.5387C28.6069 26.9712 28.6069 26.3009 28.3198 25.7333C28.1755 25.448 27.9098 25.1822 27.3783 24.6508L27.1835 24.4559C26.8339 24.1063 26.6591 23.9315 26.4833 23.8172C25.8204 23.3862 24.9658 23.3862 24.3029 23.8172C24.1271 23.9315 23.9523 24.1063 23.6027 24.4559C23.4884 24.5702 23.4313 24.6274 23.3644 24.6752C23.127 24.8453 22.7828 24.904 22.5024 24.8222C22.4235 24.7992 22.3612 24.7693 22.2365 24.7094C20.7869 24.0134 19.4282 23.0646 18.2266 21.8631Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default AudioCallActive
