import {CustomRadioButton} from './styles'
import {RadioButtonProps} from './types'

const RadioButton = (props: RadioButtonProps) => {
  const {
    color = 'var(--brand-primary-color)',
    selected = false,
    backgroundColor = '',
  } = props || {}

  return (
    <CustomRadioButton
      color={color}
      selected={selected}
      backgroundColor={backgroundColor}
    />
  )
}

export default RadioButton
