import {useCallback} from 'react'
import {Spacer, Typography, TypographyVariants} from '../../atomic'
import {OptionContainer, StyledContainer} from './styles'
import {LabelRadioSelectProps} from './types'
import RadioButton from '../../atomic/RadioButton'
import {DropdownOption} from '@mosaic-wellness/fe-types'

const LabelRadioSelect = (props: LabelRadioSelectProps) => {
  const {
    label,
    options,
    selectedOption,
    onChange,
    color = 'var(--brand-primary-color)',
    isMandatory = false,
  } = props

  const renderOption = useCallback(
    (option: DropdownOption) => {
      const selected =
        option.value.toLowerCase() === selectedOption.toLowerCase()

      return (
        <OptionContainer key={option.value} onClick={onChange(option)}>
          <RadioButton color={color} selected={selected} />
          <Typography
            variant={TypographyVariants.X_SMALL}
            customClassName="optionLabel"
          >
            {option.label}
          </Typography>
        </OptionContainer>
      )
    },
    [selectedOption, onChange, color]
  )

  return (
    <StyledContainer>
      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="label"
      >
        {label}
        {isMandatory && '*'}
      </Typography>
      <Spacer variant="small" />
      <div className="flexRowGap">{options.map(renderOption)}</div>
    </StyledContainer>
  )
}

export default LabelRadioSelect
