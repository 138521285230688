import {Typography, TypographyVariants} from '../../../atomic'
import {StyledLanguageListItem} from './styles'
import {LanguageListItemProps} from './types'

const LanguageListItem = (props: LanguageListItemProps) => {
  const {
    title = '',
    isSelected = false,
    onOptionSelect,
    fullWidth = false,
  } = props

  return (
    <StyledLanguageListItem
      as="div"
      onClick={onOptionSelect}
      className={isSelected ? 'selected-language' : ''}
      fullWidth={fullWidth}
    >
      <Typography variant={TypographyVariants.SUBHEADING_BOLD}>
        {title}
      </Typography>
    </StyledLanguageListItem>
  )
}

export default LanguageListItem
