import styled from 'styled-components'

export const StyledCategoryListItem = styled.div`
  border-radius: var(--dls-size-16);
  border: var(--dls-size-1) solid var(--brand-primary-light-divider-color);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.selected-category {
    border-color: var(--dls-brand-primary-color-500);
    color: var(--dls-brand-primary-color-500);
    background-color: var(--dls-brand-primary-color-100);
  }

  .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .avatar {
    border-radius: 10px;
  }
`
