import {useEffect, useMemo, useState, useCallback} from 'react'
import {LabelSearchInputWithTagsProps} from './types'
import noop from 'lodash/noop'
import {DropdownOption} from '@mosaic-wellness/fe-types'

const useLabelSearchInputWithTags = (props: LabelSearchInputWithTagsProps) => {
  const {
    suggestions,
    submitSelectedSuggestions,
    selectedSuggestions: sSuggestions,
    searchValue = '',
    handleSearchChange = noop,
    allowCustom = true,
  } = props

  const [inputValue, setInputValue] = useState(searchValue)
  const [selectedSuggestions, setSelectedSuggestions] =
    useState<string[]>(sSuggestions)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setSelectedSuggestions(sSuggestions)
  }, [sSuggestions])

  useEffect(() => {
    submitSelectedSuggestions(selectedSuggestions)
  }, [selectedSuggestions, submitSelectedSuggestions])

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
      handleSearchChange(event)
    },
    [handleSearchChange]
  )

  const onSubmitEditingOrBlur = useCallback(() => {
    setFocused(false)
    if (!allowCustom) {
      setInputValue('')
      return
    }
    if (inputValue.replace(/ /g, '') === '') return

    setSelectedSuggestions((prev) => [...prev, inputValue])
    setInputValue('')
  }, [inputValue])

  const onClose = useCallback(() => {
    setFocused(false)
    setInputValue('')
  }, [])

  const onSuggestionSelect = useCallback((suggestion: DropdownOption) => {
    setSelectedSuggestions((prev) =>
      prev.includes(suggestion.value)
        ? prev.filter(
            (selectedSuggestion) => selectedSuggestion !== suggestion.value
          )
        : [...prev, suggestion.value]
    )
    setInputValue('')
  }, [])

  const filteredSuggestions = useMemo(() => {
    const modifiedSuggestions: any = []
    suggestions.forEach((suggestion: string) => {
      if (
        (!inputValue && focused) ||
        (inputValue &&
          suggestion.toLowerCase().includes(inputValue.toLowerCase()))
      ) {
        modifiedSuggestions.push({
          label: suggestion,
          value: suggestion,
        })
      }
    })

    return modifiedSuggestions
  }, [inputValue, suggestions, focused])

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onSubmitEditingOrBlur()
      }
    },
    [onSubmitEditingOrBlur]
  )

  const onFocus = () => {
    setFocused(true)
  }

  return {
    inputValue,
    selectedSuggestions,
    onChangeText,
    filteredSuggestions,
    onSuggestionSelect,
    onSubmitEditingOrBlur,
    onClose,
    handleInputKeyDown,
    onFocus,
  }
}

export default useLabelSearchInputWithTags
