import styled from 'styled-components'

export const Wrapper = styled.div`
  .alert-message {
    display: flex;
    padding: var(--dls-size-8);
    align-items: center;
    gap: var(--dls-size-8);

    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-brand-primary-color-200);
    background: var(--dls-brand-primary-color-100);
  }

  .alert-message-icon-layout {
    display: flex;
    width: var(--dls-size-40);
    height: var(--dls-size-40);
    padding: var(--dls-size-4) var(--dls-size-8);
    align-items: center;
    gap: 10px;
  }

  .alert-icon {
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    fill: var(--dls-brand-primary-color-500);
  }

  .alert-message-title {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--dls-size-4);
  }
  .title {
    flex: 1 0 0;

    color: var(--dls-brand-primary-color-500);
  }
  .subtitle {
    width: 246px;

    color: var(--dls-brand-primary-color-500);
  }
`
