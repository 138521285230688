import {TypographyVariants} from '../../atomic'
import {ExpandableSection} from '../../molecules'
import {ConsultRatingExperienceWrapper, InputList} from './styles'
import InputCard from './InputCard'
import noop from 'lodash/noop'
import {ConsultRatingExperienceProps} from './types'

const ConsultRatingExperience = (props: ConsultRatingExperienceProps) => {
  const {title = '', inputs = [], handleReaction = noop, doctorExperience} = props

  return (
    <ConsultRatingExperienceWrapper>
      <ExpandableSection
        variant={TypographyVariants.CTA_LABEL_SMALL}
        customTitleClassName="titleWrapper"
        title={title}
        wrapperClassName="headerWrapper"
      >
        <InputList>
          {inputs.map((input, index) => {
            return (
              <InputCard
                key={'rating_experience_' + index}
                doctorExperience={doctorExperience}
                {...input}
                handleReaction={handleReaction}
              />
            )
          })}
        </InputList>
      </ExpandableSection>
    </ConsultRatingExperienceWrapper>
  )
}
export default ConsultRatingExperience
