import styled from 'styled-components'

export const StyledPreviewProductHeaderWrapper = styled.div`
  display: flex;
  gap: var(--dls-size-8);
  align-items: stretch;

  .image-container {
    max-width: 120px;
    border-radius: 12px;
    background-color: var(--category-primary-bg1-color);
    .product-image {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  .info-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
  }

  .name {
    line-height: 120%;
  }

  .product-for span {
    color: var(--category-primary-color);
  }

  .price-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--category-primary-color);
  }

  .discount_text {
    border-radius: 10px;
    padding: 2px 6px;
    background: var(--category-primary-color);
    background-color: var(--brand-primary-light-dark-yellow-color);
    margin-left: 10px;
  }

  .discount-price {
    text-decoration: line-through;
    color: var(--brand-primary-grey-color);
    margin-left: 4px;
  }

  .rating-section {
    width: 100%;
    color: var(--brand-primary-grey-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rating-section__rating_container {
    display: flex;
    align-items: center;
  }

  .rating-section__divider {
    width: 1px;
    height: 10px;
    background-color: var(--brand-primary-grey-color);
  }
`

export const StyledConcern = styled.div`
  padding: var(--dls-size-4) 10px;
  border-radius: var(--dls-size-8);
  background: var(--category-primary-bg1-color);
  color: var(--brand-primary-color);
`
