import styled from 'styled-components'

export const ProductCardV2Container = styled.a`
  display: flex;
  flex-direction: column;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  border: 1px solid var(--brand-primary-silver-color);
  box-sizing: border-box;
  border-radius: var(--dls-size-16);
  cursor: pointer;

  .img-container {
    display: flex;
  }

  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .product-info {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .rating-container {
    margin-top: var(--dls-size-4);
    display: flex;
    color: var(--dls-black-color);
    font-family: var(--brand-font-family-text);
    line-height: 1;
    align-items: center;
  }

  .rating {
    display: flex;

    > * {
      height: 12px;
      width: 12px;
    }
  }

  .ratinginfo {
    display: flex;
    align-items: center;
    margin-left: 2px;
    line-height: 1;
    margin-top: 4px;
    color: var(--dls-secondary-text-colors);
  }

  .review-count {
    margin-left: var(--dls-size-4);
  }
  .product-name {
    font-style: normal;
    color: var(--brand-primary-text-color);
    margin-top: var(--dls-size-8);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-space;
  }

  .price-ctn {
    display: flex;
    align-items: flex-end;
    margin-top: 2px;
  }

  .price {
    color: var(--brand-primary-text-color);
  }

  .discounted-price {
    text-decoration: line-through;
    color: var(--dls-disabled-text-colors);
    margin-left: 5px;
  }

  .pricing {
    margin-top: auto;
  }

  .whyThisWorks {
    margin-bottom: 0px;
    line-height: 1;

    .why-this-work-item {
      display: flex;
    }
    .key {
      display: inline-block;
      margin-right: var(--dls-size-4);
      color: var(--dls-brand-primary-color-500);
    }
    .text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .button-container {
    padding: var(--dls-size-8);
  }
  .atc-btn {
    margin-top: auto;
    border-radius: var(--dls-size-8);
    outline: none;
    border: 1px solid var(--dls-brand-primary-color-500);
    background-color: var(--dls-white-color);
    white-space: nowrap;
    transition: all 0.5s ease;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 100%;
    color: var(--dls-brand-primary-color-500);
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
  }

  .price-top-container {
    display: flex;
  }

  @media screen and (min-width: 786px) {
    width: 250px;
    height: auto;

    .atc-btn {
      padding: var(--dls-size-16);
    }
  }
`
