import {useState} from 'react'
import {LanguageListProps, LanguageOption} from './types'

const useLanguageListSelection = (props: LanguageListProps) => {
  const {onLanguageSelect, selectedLanguage: preSelection = ''} = props || {}
  const [selectedLanguage, setSelectedLanguage] = useState<string>(preSelection)

  const handleLanguageSelect = (selection: LanguageOption) => () => {
    if (selectedLanguage === selection.value) {
      return
    }
    onLanguageSelect(selection)
    setSelectedLanguage(selection.value)
  }

  return {
    handleLanguageSelect,
    selectedLanguage,
  }
}

export default useLanguageListSelection
