import React from 'react'

const RightArrowCred = () => {
  return (
    <svg
      width="17"
      height="7"
      viewBox="0 0 17 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 2.57468L0.9375 2.57468L0.9375 3.69968L1.5 3.69968L1.5 2.57468ZM1.5 3.69968L15.75 3.69968L15.75 2.57468L1.5 2.57468L1.5 3.69968ZM13.0343 0.137207C13.0343 2.08719 14.5687 3.69971 16.5 3.69971L16.5 2.57471C15.2245 2.57471 14.1593 1.50093 14.1593 0.137207L13.0343 0.137207ZM16.5 2.57471C14.5687 2.57471 13.0342 4.1872 13.0342 6.13721H14.1592C14.1592 4.7735 15.2245 3.69971 16.5 3.69971V2.57471Z"
        fill="#0D0D0D"
      />
    </svg>
  )
}

export default RightArrowCred
