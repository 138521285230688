import styled from 'styled-components'

export const ConsultRatingAdditionalInfoWrapper = styled.div`
  .titleWrapper {
    color: var(--dls-primary-text-colors) !important;
  }
  .headerWrapper {
    background-color: var(--dls-white-color) !important;
    padding: var(--dls-size-16) !important;
    border-radius: var(--dls-size-16) !important;
  }
`
export const InputWrapper = styled.div<{isFocused: boolean}>`
  padding-top: var(--dls-size-16);
  .contentWrapper {
    display: flex;
    align-items: flex-end;
    padding: var(--dls-size-8);
    padding-left: var(--dls-size-16);
    border-radius: var(--dls-size-8);
    border: var(--dls-size-1) solid
      ${(props) =>
        props.isFocused
          ? 'var(--dls-brand-primary-color-500)'
          : 'var(--dls-divider-light-color)'};
  }
  textarea {
    width: 100%;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    color: var(--dls-primary-text-colors);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }
  textarea::placeholder {
    opacity: 0.6;
  }
`
