import {useState} from 'react'
import {CategoryListProps} from './types'
import {SOCategorySelectionData} from '@mosaic-wellness/fe-types'

const useCategoryListSelection = (props: CategoryListProps) => {
  const {onCategorySelect, selectedCategory: preSelection = ''} = props || {}
  const [selectedCategory, setSelectedCategory] = useState<string>(preSelection)

  const handleCategorySelect = (selection: SOCategorySelectionData) => () => {
    if (selectedCategory === selection.value) {
      return
    }
    onCategorySelect(selection)
    setSelectedCategory(selection.value)
  }

  return {
    handleCategorySelect,
    selectedCategory,
  }
}

export default useCategoryListSelection
