import noop from 'lodash/noop'
import {IProductCardProps} from '../components/specifics/ProductCard/ProductCard.interface'
import {useCallback, useMemo} from 'react'
import isEmpty from 'lodash/isEmpty'

export function useProductCard(props: IProductCardProps) {
  const {product} = props
  const {
    discountedPrice = 0,
    image = '',
    price = 0,
    whyThisWorks,
    cta,
    cardClickAction,
    genericAction = noop,
  } = product || {}

  const imageToRender = useMemo(() => {
    if (image) {
      return Array.isArray(image) ? image[0] : image
    }

    return ''
  }, [image])

  const showWhyThisWorks = useMemo(() => {
    if (Array.isArray(whyThisWorks)) {
      if (!isEmpty(whyThisWorks)) {
        return true
      }
    }

    return false
  }, [whyThisWorks])

  const showDiscountedPrice = useMemo(() => {
    return discountedPrice && discountedPrice !== 0 && price > discountedPrice
  }, [discountedPrice, price])

  const handleCtaClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      const defaultCta = {actionName: 'ADD_TO_CART', params: product}
      genericAction(cta || defaultCta)
    },
    [cta, genericAction, product]
  )

  const handleCardClick = useCallback(() => {
    const defaultCardAction = {actionName: 'OPEN_PDP', params: product}
    genericAction(cardClickAction || defaultCardAction)
  }, [cardClickAction, genericAction, product])

  return [
    {imageToRender, showWhyThisWorks, showDiscountedPrice},
    {handleCtaClick, handleCardClick},
  ]
}
