import {useMemo, useState} from 'react'
import {InputCardProps} from '../types'

const useInputCard = (props: InputCardProps) => {
  const {handleReaction, value, doctorExperience} = props
  const [isLiked, setIsLiked] = useState<boolean | null>(
    doctorExperience?.[value] ?? null
  )

  const handleVote = (val: boolean) => () => {
    setIsLiked(val)
    handleReaction({key: value, value: val})
  }
  const ThumbsUpStyle = useMemo(() => {
    return isLiked
      ? {
          stroke: 'var(--dls-success-color-500)',
          fill: 'var(--dls-success-color-100)',
        }
      : {
          stroke: 'var(--dls-disabled-text-colors)',
          fill: 'var(--dls-white-color)',
        }
  }, [isLiked])

  const ThumbsDownStyle = useMemo(() => {
    return isLiked === false
      ? {
          stroke: 'var(--dls-error-color-500)',
          fill: 'var(--dls-error-color-100)',
        }
      : {
          stroke: 'var(--dls-disabled-text-colors)',
          fill: 'var(--dls-white-color)',
        }
  }, [isLiked])

  return {
    handleVote,
    isLiked,
    setIsLiked,
    ThumbsUpStyle,
    ThumbsDownStyle,
  }
}

export default useInputCard
