import React from 'react'
import Typography from '../Typography'
import {TypographyVariants} from '../Typography/types'
import {StyledSelectInput, StyledSelectInputMain} from './styles'
import {SelectInputProps} from './types'

const SelectInput = (props: SelectInputProps) => {
  const {label, options, ...rest} = props

  return (
    <StyledSelectInputMain>
      {label ? (
        <label htmlFor={label} className="label">
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="secondary"
          >
            {label}
          </Typography>
        </label>
      ) : null}
      <StyledSelectInput {...rest}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelectInput>
    </StyledSelectInputMain>
  )
}

export default SelectInput
