import {StyledRxConsultDetailsWrapper} from './styles'
import {Info, Typography, TypographyVariants} from '../../atomic'
import noop from 'lodash/noop'
import {RxConsultDetailsProps} from './types'

const RxConsultDetails = (props: RxConsultDetailsProps) => {
  const {
    consultationId = '',
    duration = '',
    price = '',
    hideInfoIcon = true,
    onInfoClick = noop,
  } = props

  return (
    <StyledRxConsultDetailsWrapper>
      <div className="item">
        <Typography variant={TypographyVariants.X_SMALL}>
          Consultation ID
        </Typography>
        <Typography variant={TypographyVariants.TAG_BOLD}>
          {consultationId}
        </Typography>
      </div>
      {duration && (
        <>
          <span className="divider" />
          <div className="item">
            <Typography variant={TypographyVariants.X_SMALL}>
              Duration
            </Typography>
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {duration}
            </Typography>
          </div>
        </>
      )}
      {price && (
        <>
          <span className="divider" />
          <div className="item">
            <Typography variant={TypographyVariants.X_SMALL}>
              Total Billing
            </Typography>
            <div className="price-container">
              <Typography variant={TypographyVariants.TAG_BOLD}>
                <span dangerouslySetInnerHTML={{__html: price}} />
              </Typography>
              {hideInfoIcon ? null : (
                <Info className="info-icon" onClick={onInfoClick} />
              )}
            </div>
          </div>
        </>
      )}
    </StyledRxConsultDetailsWrapper>
  )
}

export default RxConsultDetails
