import {
  AccordionRight,
  Calendar,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {PrescribedProductCardWrapper} from './styles'
import noop from 'lodash/noop'
import {PrescribedProductCardProps} from './types'

const PrescribedProductCard = (props: PrescribedProductCardProps) => {
  const {
    imgUrl = '',
    title = '',
    onClick = noop,
    frequency = '',
    time = '',
    instruction = '',
    headerText = '',
    hideRightArrow = false,
    prescribedFor = '',
  } = props || {}

  return (
    <PrescribedProductCardWrapper
      hideRightArrow={hideRightArrow}
      isHeaderPresent={!!headerText}
    >
      {headerText ? (
        <div className="header">
          <Typography variant={TypographyVariants.TAG_REGULAR}>
            {headerText}
          </Typography>
        </div>
      ) : null}
      <div className="body">
        <div className="detail" onClick={onClick}>
          {imgUrl && (
            <div className="img-container">
              <OptimizedImage
                data-testid="prescribed-product-icon"
                source={imgUrl}
                customClassName="product-image"
              />
            </div>
          )}
          <Typography
            variant={TypographyVariants.TAG_BOLD}
            customClassName="title"
          >
            {title}
          </Typography>
          {hideRightArrow ? null : <AccordionRight />}
        </div>
        {prescribedFor ? (
          <div className="prescribed-for">
            <Typography variant={TypographyVariants.X_SMALL}>
              {prescribedFor}
            </Typography>
          </div>
        ) : null}
        {frequency || time ? (
          <>
            {!prescribedFor && <div className="divider" />}
            <div className="frequency">
              <Typography
                variant={TypographyVariants.TAG_REGULAR}
                customClassName="info"
              >
                <span dangerouslySetInnerHTML={{__html: frequency}} />
              </Typography>
              {time ? (
                <div className="frequency-time">
                  <Calendar />
                  <Typography
                    variant={TypographyVariants.TAG_REGULAR}
                    customClassName="info"
                  >
                    {time}
                  </Typography>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {instruction ? (
          <>
            <div className="divider" />
            <div className="instruction">
              <Typography
                variant={TypographyVariants.TAG_REGULAR}
                customClassName="info"
              >
                {instruction}
              </Typography>
            </div>
          </>
        ) : null}
      </div>
    </PrescribedProductCardWrapper>
  )
}

export default PrescribedProductCard
