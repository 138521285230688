import {ImageOrAnimationBannerProps} from '@mosaic-wellness/fe-types'
import {useEffect, useRef, useState} from 'react'

const useImageOrAnimationBanner = (props: ImageOrAnimationBannerProps) => {
  const {aspectRatio = 1} = props
  const [height, setHeight] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth
      setHeight(width / aspectRatio)
    }
  }, [aspectRatio])

  return {
    height,
    containerRef,
  }
}

export default useImageOrAnimationBanner
