import styled from 'styled-components'

export const InputCardWrapper = styled.div`
  .inputCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reactionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-8);
  }
  .reactionIcon {
    cursor: pointer;
    padding: var(--dls-size-4);
  }
`
