const EnterArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96964 4.46973L2.30297 8.13639L1.74908 8.69028L2.32651 9.21959L6.32651 12.8863L7.3401 11.7805L4.7614 9.41672H12.5H13.25V8.66672V5.33339H11.75V7.91672H4.64396L7.0303 5.53039L5.96964 4.46973Z"
      fill="white"
    />
  </svg>
)

export default EnterArrow
