import {RxDiagnosisProps} from '@mosaic-wellness/fe-types'
import {Typography, TypographyVariants} from '../../atomic'
import {StyledRxDiagnosisWrapper} from './styles'

const RxDiagnosis = (props: RxDiagnosisProps) => {
  const {title = ''} = props

  return (
    <StyledRxDiagnosisWrapper>
      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="text"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </Typography>
    </StyledRxDiagnosisWrapper>
  )
}

export default RxDiagnosis
