import {useState, useCallback} from 'react'
import {DoctorReviewProps} from './types'
import noop from 'lodash/noop'

const useInputHandler = (props: DoctorReviewProps) => {
  const [inputVal, setInputVal] = useState<string>('')
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const {handleCtaClick = noop} = props || {}

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [isFocused])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [isFocused])

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInputVal(e.target.value)
      handleCtaClick(e.target.value)
    },
    [inputVal]
  )

  return {
    inputVal,
    isFocused,
    handleFocus,
    handleBlur,
    handleTextChange,
  }
}

export default useInputHandler
