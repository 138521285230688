import {IconProps} from '../types'

const Reviews = ({width = 16, height = 16, fill = '#666666'}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29385 9.20793L7.99635 8.19776L9.69885 9.20793L9.23735 7.30793L10.736 6.02976L8.76552 5.85276L7.99635 4.06959L7.22719 5.85276L5.25669 6.02976L6.75535 7.30793L6.29385 9.20793ZM1.92969 13.7593V3.00543C1.92969 2.70532 2.0333 2.45165 2.24052 2.24443C2.44774 2.03721 2.70141 1.93359 3.00152 1.93359H12.9912C13.2913 1.93359 13.545 2.03721 13.7522 2.24443C13.9594 2.45165 14.063 2.70532 14.063 3.00543V10.5951C14.063 10.8952 13.9594 11.1489 13.7522 11.3561C13.545 11.5633 13.2913 11.6669 12.9912 11.6669H4.02202L1.92969 13.7593ZM3.66302 10.8003H12.9912C13.0425 10.8003 13.0895 10.7789 13.1322 10.7361C13.175 10.6934 13.1964 10.6464 13.1964 10.5951V3.00543C13.1964 2.95409 13.175 2.90709 13.1322 2.86443C13.0895 2.82165 13.0425 2.80026 12.9912 2.80026H3.00152C2.95019 2.80026 2.90319 2.82165 2.86052 2.86443C2.81774 2.90709 2.79635 2.95409 2.79635 3.00543V11.6734L3.66302 10.8003Z"
        fill={fill}
      />
    </svg>
  )
}

export default Reviews
