import {useCallback} from 'react'

export const useToggleBodyScroll = () => {
  const disableBodyScroll = useCallback(() => {
    document.body.style.overflow = 'hidden'

    if (typeof document !== 'undefined') {
      document.body.style.height = `${window.scrollY}px`
    }
  }, [])

  const enableBodyScroll = useCallback(() => {
    document.body.style.overflow = 'auto'
    document.body.style.height = 'unset'
  }, [])

  return {
    disableBodyScroll: disableBodyScroll,
    enableBodyScroll: enableBodyScroll,
  }
}
