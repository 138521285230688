import styled, {css} from 'styled-components'

export const StyledExpandableSection = styled.div<{
  isCollapsible: boolean
  subTitleAmountColor: string
}>`
  .wrapper {
    padding: var(--dls-size-8) 10px;
    border-radius: var(--dls-size-4);
    border: var(--dls-size-1) solid var(--dls-divider-light-color);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      max-width: 90%;
      color: var(--brand-light-secondary-text-color);
    }
    ${({isCollapsible}) =>
      isCollapsible &&
      css`
        cursor: pointer;
      `};
  }

  .subTitleContainer {
    display: flex;
    flex-direction: row;
    gap: var(--dls-size-8);
    align-items: center;

    .subtitle {
      color: ${({subTitleAmountColor}) =>
        subTitleAmountColor
          ? subTitleAmountColor
          : 'var(--brand-primary-text-color)'};
    }
  }
`
