import {useState, useEffect, useCallback, useRef, useMemo} from 'react'
import {GenericCarouselProps} from './types'

const useGenericCarousel = (props: GenericCarouselProps) => {
  const {
    isInfinite = true,
    isAutoSlide = false,
    autoSlideInterval = 3000,
    pauseAutoSlideDuration = 2000,
    isTransitionEnabled = true,
    slidesToShow = 1,
    cardData = [],
  } = props || {}
  const [index, setIndex] = useState(isInfinite ? 1 : 0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStartPosition, setDragStartPosition] = useState<number | null>(
    null
  )

  const slideRef = useRef<HTMLDivElement>(null)
  const autoSlideRef = useRef<number | null>(null)
  const pauseTimeoutRef = useRef<number | null>(null)

  const slides = useMemo(() => {
    return isInfinite
      ? [cardData[cardData.length - 1], ...cardData, cardData[0]]
      : cardData
  }, [cardData, isInfinite])

  const handleTransitionEnd = useCallback(() => {
    if (isInfinite && slideRef.current) {
      setIsTransitioning(false)
      if (index === slides.length - 1) {
        setIndex(1)
        slideRef.current.style.transition = 'none'
        slideRef.current.style.transform = `translateX(-${100 / slidesToShow}%)`
      }
      if (index === 0) {
        setIndex(slides.length - 2)
        slideRef.current.style.transition = 'none'
        slideRef.current.style.transform = `translateX(-${
          (slides.length - 2) * (100 / slidesToShow)
        }%)`
      }
    } else {
      setIsTransitioning(false)
    }
  }, [isInfinite, setIndex, index, slides.length, slidesToShow])

  const pauseAutoSlide = useCallback(() => {
    setIsPaused(true)
    if (pauseTimeoutRef.current) {
      clearTimeout(pauseTimeoutRef.current)
    }
    pauseTimeoutRef.current = window.setTimeout(() => {
      setIsPaused(false)
    }, pauseAutoSlideDuration)
  }, [pauseAutoSlideDuration])

  const moveToNextSlide = useCallback(() => {
    if (isTransitioning) return
    if (!isInfinite && index === slides.length - 1) return
    pauseAutoSlide()
    setIsTransitioning(true)
    setIndex((prevIndex) => prevIndex + 1)
    setTouchPosition(null)
  }, [index, isInfinite, isTransitioning, pauseAutoSlide, slides.length])

  const moveToPreviousSlide = useCallback(() => {
    if (isTransitioning) return
    if (!isInfinite && index === 0) return
    pauseAutoSlide()
    setIsTransitioning(true)
    setIndex((prevIndex) => prevIndex - 1)
    setTouchPosition(null)
  }, [index, isInfinite, isTransitioning, pauseAutoSlide])

  const moveToNextNormalSlide = useCallback(() => {
    if (!slideRef.current) return
    if (isInfinite) {
      setIndex((prevIndex) => {
        if (prevIndex >= slides.length - 1) {
          return 1
        }
        return prevIndex + 1
      })
    } else {
      if (index >= slides.length - 1) return
      setIndex((prevIndex) => prevIndex + 1)
    }
  }, [index, isInfinite, slides.length])

  const moveToPreviousNormalSlide = useCallback(() => {
    if (isInfinite) {
      setIndex((prevIndex) => {
        if (prevIndex <= 0) {
          return slides.length - 2
        }
        return prevIndex - 1
      })
    } else {
      if (index === 0) return
      setIndex((prevIndex) => prevIndex - 1)
    }
  }, [index, isInfinite, slides.length])

  const handleTouchStart = useCallback(
    (e: React.TouchEvent) => {
      const touchDown = e.touches[0].clientX
      setTouchPosition(touchDown)
    },
    [setTouchPosition]
  )

  const slideThreshold = 5

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (touchPosition === null) return

      const currentTouch = e.touches[0].clientX
      const diff = touchPosition - currentTouch

      if (diff > slideThreshold) {
        moveToNextSlide()
      } else if (diff < -slideThreshold) {
        moveToPreviousSlide()
      }

      setTouchPosition(null)
    },
    [touchPosition, moveToNextSlide, moveToPreviousSlide, setTouchPosition]
  )

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setIsDragging(true)
      setDragStartPosition(e.clientX)
    },
    [setIsDragging, setDragStartPosition]
  )

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging || dragStartPosition === null) return

      const diff = dragStartPosition - e.clientX

      if (diff > slideThreshold) {
        moveToNextSlide()
        setIsDragging(false)
      } else if (diff < -slideThreshold) {
        moveToPreviousSlide()
        setIsDragging(false)
      }
    },
    [
      isDragging,
      dragStartPosition,
      moveToNextSlide,
      moveToPreviousSlide,
      setIsDragging,
    ]
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [setIsDragging])

  const handleMouseLeave = useCallback(() => {
    setIsDragging(false)
  }, [setIsDragging])

  useEffect(() => {
    if (!isTransitioning && slideRef.current) {
      slideRef.current.style.transition = 'transform 0.7s ease-out'
    }
  }, [isTransitioning])

  useEffect(() => {
    if (isAutoSlide && !isPaused) {
      autoSlideRef.current = window.setInterval(() => {
        if (isTransitionEnabled) {
          moveToNextSlide()
        } else {
          moveToNextNormalSlide()
        }
      }, autoSlideInterval)
    }
    return () => {
      if (autoSlideRef.current) {
        clearInterval(autoSlideRef.current)
      }
    }
  }, [
    isAutoSlide,
    autoSlideInterval,
    isPaused,
    moveToNextNormalSlide,
    moveToNextSlide,
    isTransitionEnabled,
  ])

  useEffect(() => {
    if (index > slides.length - 1) {
      setIndex(isInfinite ? 1 : 0)
    }
  }, [index, slides, isInfinite])

  return {
    index,
    setIndex,
    isTransitioning,
    slideRef,
    handleTransitionEnd,
    moveToNextSlide,
    moveToPreviousSlide,
    moveToNextNormalSlide,
    moveToPreviousNormalSlide,
    setTouchPosition,
    setDragStartPosition,
    setIsDragging,
    isDragging,
    dragStartPosition,
    touchPosition,
    slides,
    handleTouchStart,
    handleTouchMove,
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
    handleMouseLeave,
  }
}

export default useGenericCarousel
