import styled from 'styled-components'

const StyledSpacer = styled.div`
  &[data-variant='x-small'] {
    height: 4px;
  }
  &[data-variant='small'] {
    height: 8px;
  }
  &[data-variant='medium'] {
    height: 12px;
  }
  &[data-variant='large'] {
    height: 16px;
  }
  &[data-variant='x-large'] {
    height: 24px;
  }
  &[data-variant='xx-large'] {
    height: 32px;
  }
`

export {StyledSpacer}
