import WalletTransactionSummaryPageShimmer from 'src/Screens/WalletTransactionSummary/WalletTransactionSummaryPageShimmer'
import {Typography, TypographyVariants} from '../../atomic'
import useTabs from './hook'
import {Tab, TabContent, TabsContainer} from './styles'
import {TabsViewProps} from './types'
import {useCallback, useMemo} from 'react'
import {checkIsAppViaParam} from 'src/utils/checkIsApp'

const WalletTransactionSummaryTabs = (props: TabsViewProps) => {
  const {tabs, isContentLoading = false} = props

  const {activeIndex, handleTabClick} = useTabs()

  const isAppViaParam = checkIsAppViaParam()

  const renderVariant = useCallback(
    (index: number) => {
      if (activeIndex === index) {
        return TypographyVariants.BODY_BASE_BOLD
      } else {
        return TypographyVariants.BODY_BASE_REGULAR
      }
    },
    [activeIndex]
  )

  const tabTitles = useMemo(() => {
    return tabs?.map((tab, index) => (
      <Tab
        key={tab.name || index}
        isActive={activeIndex === index}
        onClick={() => handleTabClick(index)}
      >
        <Typography variant={renderVariant(index)}>{tab.name}</Typography>
      </Tab>
    ))
  }, [activeIndex, handleTabClick, renderVariant, tabs])

  const tabsToRender = useMemo(() => {
    const tab = tabs?.find((tab, index) => {
      return activeIndex === index
    })
    return tab?.component
  }, [activeIndex, tabs])

  return (
    <>
      <TabsContainer isApp={isAppViaParam}>{tabTitles}</TabsContainer>
      <TabContent>
        {isContentLoading ? (
          <WalletTransactionSummaryPageShimmer />
        ) : (
          tabsToRender && tabsToRender()
        )}
      </TabContent>
    </>
  )
}

export default WalletTransactionSummaryTabs
