import {IconProps} from '../types'

const SessionDone = ({
  width = 16,
  height = 16,
  fill = '#666666',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.80152 14.0669C3.50574 14.0669 3.25313 13.9623 3.04369 13.7529C2.83435 13.5435 2.72969 13.2909 2.72969 12.9951V6.17976C2.50069 6.11221 2.31008 5.97993 2.15785 5.78293C2.00574 5.58593 1.92969 5.36604 1.92969 5.12326V3.00543C1.92969 2.70965 2.03435 2.45704 2.24369 2.24759C2.45313 2.03826 2.70574 1.93359 3.00152 1.93359H12.9912C13.287 1.93359 13.5396 2.03826 13.749 2.24759C13.9584 2.45704 14.063 2.70965 14.063 3.00543V5.12326C14.063 5.36604 13.987 5.58593 13.8349 5.78293C13.6826 5.97993 13.492 6.11221 13.263 6.17976V12.9929C13.263 13.2935 13.1584 13.5477 12.949 13.7554C12.7396 13.9631 12.487 14.0669 12.1912 14.0669H3.80152ZM3.59635 6.19509V12.9631C3.59635 13.0314 3.62091 13.088 3.67002 13.1329C3.71924 13.1778 3.78019 13.2003 3.85285 13.2003H12.1912C12.2511 13.2003 12.3002 13.181 12.3387 13.1426C12.3771 13.1041 12.3964 13.055 12.3964 12.9951V6.19509H3.59635ZM3.00152 5.32843H12.9912C13.0511 5.32843 13.1002 5.3092 13.1387 5.27076C13.1771 5.23232 13.1964 5.18315 13.1964 5.12326V3.00543C13.1964 2.94554 13.1771 2.89637 13.1387 2.85793C13.1002 2.81948 13.0511 2.80026 12.9912 2.80026H3.00152C2.94163 2.80026 2.89247 2.81948 2.85402 2.85793C2.81558 2.89637 2.79635 2.94554 2.79635 3.00543V5.12326C2.79635 5.18315 2.81558 5.23232 2.85402 5.27076C2.89247 5.3092 2.94163 5.32843 3.00152 5.32843ZM6.52452 8.82209H9.46819V8.00026H6.52452V8.82209Z"
        fill={fill}
      />
    </svg>
  )
}

export default SessionDone
