import React from 'react'

interface IChevronUpProps {
  color?: string
}

function ChevronUp(props: IChevronUpProps) {
  const {color = '#101828'} = props

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 15.0825L12 9.08252L6 15.0825"
        stroke={color}
        style={{
          stroke: color,
          strokeOpacity: 1,
        }}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronUp
