import React from 'react'
import {IconProps, RatingProps} from './RatingStar.interface'

const FullStar = ({size = 24, color = '#F6CA4C'}: IconProps) => {
  return (
    <div style={{color: color, display: 'flex'}}>
      <svg height={size} viewBox="0 0 24 24">
        <path
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
          fill="currentColor"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  )
}

const HalfStar = ({size = 24, color = '#F6CA4C'}: IconProps) => {
  return (
    <div style={{color: color, display: 'flex'}}>
      <svg height={size} viewBox="0 0 24 24">
        <path
          d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
          fill="currentColor"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  )
}

const EmptyStar = ({size = 24, color = '#F6CA4C'}: IconProps) => {
  return (
    <div style={{color: color, display: 'flex'}}>
      <svg height={size} viewBox="0 0 24 24">
        <path
          d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
          fill="currentColor"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  )
}

/** @description The RatingStar component renders a customizable star rating system. It allows you to display a rating with full, half, or empty stars based on the provided value. */
const RatingStar = (props: RatingProps) => {
  const {
    className,
    count = 1,
    value = 0,
    color = '#F6CA4C',
    activeColor = '#F6CA4C',
    size = 24,
    isHalf = true,
    emptyIcon = <EmptyStar />,
    halfIcon = <HalfStar />,
    fullIcon = <FullStar />,
  } = props || {}

  if (value === null || isNaN(value)) {
    return null
  }
  const getColor = (index: number) => {
    if (value > index) {
      return activeColor
    }
    return color
  }

  const stars = []
  for (let i = 0; i < count; i++) {
    let star: React.ReactElement
    if (isHalf && value - i > 0 && value - i < 1) {
      star = halfIcon
    } else if (i < value) {
      star = fullIcon
    } else {
      star = emptyIcon
    }

    stars.push(
      <div key={i} style={{cursor: 'pointer', display: 'flex', flexShrink: 0}}>
        {React.cloneElement(star, {
          size: size,
          color: getColor(i),
        })}
      </div>
    )
  }
  return <div className={`rating ${className}`}>{stars}</div>
}

export default RatingStar
