import {
  Container,
  TransitionSlides,
  NormalSlides,
  Slide,
  SlideControls,
  Dot,
  DotContainer,
} from './styles'
import {LeftArrow} from '@web-components'
import {RightArrow} from '@web-components'
import {GenericCarouselProps} from './types'
import useGenericCarousel from './useGenericCarousel'
import {useDeviceTypeContext} from '@web-components'

const GenericCarousel = (props: GenericCarouselProps) => {
  const {
    isInfinite = true,
    isTransitionEnabled = true,
    slidesToShow = 1,
    showDots = true,
    slideGap = 15,
    Component,
    enableOverflow = false,
    LeftArrowIcon = LeftArrow,
    RightArrowIcon = RightArrow,
    arrowPositionY = 40,
    arrowGap = 1100,
  } = props

  const {
    index,
    slideRef,
    handleTransitionEnd,
    moveToNextSlide,
    moveToPreviousSlide,
    moveToNextNormalSlide,
    moveToPreviousNormalSlide,
    slides,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    handleTouchMove,
    handleTouchStart,
  } = useGenericCarousel(props)

  const {isMobile = false} = useDeviceTypeContext()

  return (
    <>
      <Container
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        enableOverflow={enableOverflow}
      >
        {isTransitionEnabled && (
          <TransitionSlides
            ref={slideRef}
            style={{
              transform: `translateX(${-index * (100 / slidesToShow)}%)`,
            }}
            onTransitionEnd={handleTransitionEnd}
          >
            {slides.map((slide, idx) => (
              <Slide key={idx} slidesToShow={slidesToShow} slideGap={slideGap}>
                <Component widgetData={slide} />
              </Slide>
            ))}
          </TransitionSlides>
        )}

        {!isTransitionEnabled && (
          <NormalSlides>
            {slides.map((slide, idx) => {
              if (index === idx) {
                return (
                  <Slide
                    key={idx}
                    slidesToShow={slidesToShow}
                    slideGap={slideGap}
                  >
                    <Component widgetData={slide} />
                  </Slide>
                )
              }
            })}
          </NormalSlides>
        )}
      </Container>

      {!isMobile && (
        <SlideControls arrowPositionY={arrowPositionY} arrowGap={arrowGap}>
          <button
            onClick={
              isTransitionEnabled
                ? moveToPreviousSlide
                : moveToPreviousNormalSlide
            }
            className={isInfinite || index > 0 ? 'visible' : 'hidden'}
          >
            <LeftArrowIcon />
          </button>
          <button
            onClick={
              isTransitionEnabled ? moveToNextSlide : moveToNextNormalSlide
            }
            className={
              isInfinite ||
              index <
                (isInfinite ? slides.length - 2 : slides.length / slidesToShow)
                ? 'visible'
                : 'hidden'
            }
          >
            <RightArrowIcon />
          </button>
        </SlideControls>
      )}

      {showDots && slides.length >= 2 && (
        <DotContainer>
          {Array.from({
            length: isInfinite
              ? Math.ceil(slides.length - 2 - (slidesToShow - 1))
              : Math.ceil(slides.length - (slidesToShow - 1)),
          }).map((_, idx) => (
            <Dot
              key={idx}
              data-active={
                isInfinite
                  ? (index === 0 && idx === slides.length - 2) ||
                    (index === slides.length - 1 && idx === 0) ||
                    index - 1 === idx
                  : idx === index
              }
            />
          ))}
        </DotContainer>
      )}
    </>
  )
}

export default GenericCarousel
