import styled from 'styled-components'

export const ConsultRatingExperienceWrapper = styled.div`
  .titleWrapper {
    color: var(--dls-primary-text-colors) !important;
  }
  .headerWrapper {
    background-color: var(--dls-white-color) !important;
    padding: var(--dls-size-16) !important;
    border-radius: var(--dls-size-16) !important;
  }
`

export const InputList = styled.div`
  padding-top: var(--dls-size-16);
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-16);
`
