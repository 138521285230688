import styled from 'styled-components'

export const StyledConsultPendingDuesOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--dls-size-16);
  gap: var(--dls-size-8);
  color: var(--brand-primary-white-color);
  z-index: 10;
  .cta {
    margin-top: 4px;
    background: var(--dls-brand-primary-color-100);
    border: none;
    .cta-label {
      color: var(--dls-brand-primary-color-500);
    }
  }
  .cta:hover {
    background: var(--dls-brand-primary-color-100);
  }
`
