import {CategoryListProps} from './types'
import useCategoryListSelection from './useCategoryListSelection'
import {StyledCategoryList} from './styles'
import CategoryListItem from './CategoryListItem'
import {SOCategorySelectionData} from '@mosaic-wellness/fe-types'

const CategoryList = (props: CategoryListProps) => {
  const {listItems = [], radioButtonConfig = {}} = props
  const {handleCategorySelect, selectedCategory} =
    useCategoryListSelection(props)

  return (
    <StyledCategoryList>
      {listItems.map((item: SOCategorySelectionData, index: number) => (
        <CategoryListItem
          isSelected={selectedCategory === item.value}
          onOptionSelect={handleCategorySelect(item)}
          {...item}
          key={'category_item_' + index}
          radioButtonConfig={radioButtonConfig}
        />
      ))}
    </StyledCategoryList>
  )
}

export default CategoryList
