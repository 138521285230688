import React from 'react'

const ChevronRight = ({width = 10, height = 14, color = '#000'}) => {
  return (
    <svg
      className="chevron-icon"
      width={width}
      height={height}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93106 7.54032L2.85781 13.6135C2.56491 13.9064 2.09003 13.9064 1.79716 13.6135L1.08881 12.9052C0.796407 12.6128 0.795844 12.1389 1.08756 11.8458L5.90072 7.00998L1.08756 2.1742C0.795844 1.8811 0.796407 1.4072 1.08881 1.11479L1.79716 0.406447C2.09006 0.113541 2.56494 0.113541 2.85781 0.406447L8.93103 6.47967C9.22394 6.77254 9.22394 7.24742 8.93106 7.54032Z"
        fill={color}
      />
    </svg>
  )
}

export default ChevronRight
