import React from 'react'

interface ICloseIconProps {
  width?: number
  height?: number
  fill?: string
  stroke?: string
}

function CloseIcon(props: ICloseIconProps) {
  const {width = 50, height = 50, fill = '#E86669', stroke = '#fff'} = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={width / 2} cy={height / 2} r={height / 2} fill={fill} />
      <path
        d="M32.5 17.5L17.4999 32.5001"
        stroke={stroke}
        stroke-width="4.5"
        stroke-linecap="round"
      />
      <path
        d="M32.5 32.5L17.4999 17.4999"
        stroke={stroke}
        stroke-width="4.5"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default CloseIcon
