import styled from 'styled-components'

const StyledContainer = styled.div`
  width: 100%;
  .label {
    color: var(--brand-light-secondary-text-color);
  }
`

const Input = styled.input<{showNumberControl?: boolean}>`
  width: 100%;
  padding: var(--dls-size-8) 0;
  border: none;
  border-bottom: var(--dls-size-1) solid var(--dls-divider-light-color);
  font-style: italic;
  font-size: 18px;
  color: var(--brand-light-secondary-text-color);

  &::placeholder {
    color: var(--dls-disabled-text-colors);
  }
  &:focus {
    outline: none;
  }

  ${(props) =>
    !props.showNumberControl &&
    `
    /* Hide number input controls in Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Hide number input controls in Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  `}
`

export {StyledContainer, Input}
