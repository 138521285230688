import {useState} from 'react'
import {
  ThumbsDown,
  Typography,
  TypographyVariants,
  ThumbsUp,
} from '../../../atomic'
import {InputCardWrapper} from './styles'
import {InputCardProps} from '../types'
import useInputCard from './hook'

const InputCard = (props: InputCardProps) => {
  const {title} = props
  const {handleVote, ThumbsUpStyle, ThumbsDownStyle} = useInputCard(props)
  return (
    <InputCardWrapper>
      <div className="inputCard">
        <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
          {title}
        </Typography>

        <div className="reactionWrapper">
          <div className="reactionIcon" onClick={handleVote(false)}>
            <ThumbsDown
              fill={ThumbsDownStyle.fill}
              stroke={ThumbsDownStyle.stroke}
            />
          </div>
          <div className="reactionIcon" onClick={handleVote(true)}>
            <ThumbsUp fill={ThumbsUpStyle.fill} stroke={ThumbsUpStyle.stroke} />
          </div>
        </div>
      </div>
    </InputCardWrapper>
  )
}

export default InputCard
