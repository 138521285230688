const Mediconnect = () => {
  return (
    <svg
      width="32"
      height="42"
      viewBox="0 0 32 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.69312"
        y="5"
        width="27.3054"
        height="27.3054"
        rx="2.88722"
        fill="#146053"
      />
      <rect
        x="0.00012207"
        y="9.66626"
        width="27.3054"
        height="27.3054"
        rx="2.88722"
        fill="#81B899"
      />
      <path
        d="M4.21619 36.8762H13.6054L7.80266 40.725C6.63717 41.498 6.05442 41.8845 5.57138 41.8549C5.15054 41.8291 4.76202 41.6205 4.50789 41.2841C4.21619 40.8979 4.21619 40.1987 4.21619 38.8001V36.8762Z"
        fill="#81B899"
      />
      <path
        d="M27.6881 5.09204H18.2989L24.1016 1.24328C25.2671 0.470249 25.8499 0.083734 26.3329 0.113376C26.7538 0.139201 27.1423 0.347718 27.3964 0.684152C27.6881 1.07032 27.6881 1.76959 27.6881 3.16814V5.09204Z"
        fill="#146053"
      />
      <path
        d="M27.2428 18.4054V23.5934C27.2428 24.052 27.0606 24.4919 26.7363 24.8162C26.412 25.1405 25.9721 25.3227 25.5135 25.3227H20.3255V30.5107C20.3255 30.9693 20.1433 31.4092 19.819 31.7335C19.4947 32.0578 19.0548 32.24 18.5962 32.24H13.4082C12.9496 32.24 12.5097 32.0578 12.1854 31.7335C11.8611 31.4092 11.6789 30.9693 11.6789 30.5107V25.3227H6.49092C6.03227 25.3227 5.59241 25.1405 5.2681 24.8162C4.94379 24.4919 4.7616 24.052 4.7616 23.5934V18.4054C4.7616 17.9468 4.94379 17.5069 5.2681 17.1826C5.59241 16.8583 6.03227 16.6761 6.49092 16.6761H11.6789V11.4881C11.6789 11.0295 11.8611 10.5896 12.1854 10.2653C12.5097 9.94099 12.9496 9.75879 13.4082 9.75879H18.5962C19.0548 9.75879 19.4947 9.94099 19.819 10.2653C20.1433 10.5896 20.3255 11.0295 20.3255 11.4881V16.6761H25.5135C25.9721 16.6761 26.412 16.8583 26.7363 17.1826C27.0606 17.5069 27.2428 17.9468 27.2428 18.4054Z"
        fill="white"
      />
      <rect
        x="11.6707"
        y="9.69165"
        width="2.40601"
        height="2.16541"
        fill="#148A64"
      />
      <rect
        x="17.9257"
        y="9.69165"
        width="2.40601"
        height="2.16541"
        fill="#146053"
      />
      <path
        d="M11.6707 12.0014C11.6707 11.1929 11.6707 10.7887 11.828 10.4799C11.9664 10.2082 12.1872 9.9874 12.4589 9.84899C12.7677 9.69165 13.1719 9.69165 13.9804 9.69165H18.0225C18.831 9.69165 19.2353 9.69165 19.5441 9.84899C19.8157 9.9874 20.0366 10.2082 20.175 10.4799C20.3323 10.7887 20.3323 11.1929 20.3323 12.0014V12.0977H11.6707V12.0014Z"
        fill="white"
      />
      <path
        d="M11.6707 29.9023H20.3323V29.9986C20.3323 30.8071 20.3323 31.2113 20.175 31.5201C20.0366 31.7918 19.8157 32.0126 19.5441 32.151C19.2353 32.3084 18.831 32.3084 18.0225 32.3084H13.9804C13.1719 32.3084 12.7677 32.3084 12.4589 32.151C12.1872 32.0126 11.9664 31.7918 11.828 31.5201C11.6707 31.2113 11.6707 30.8071 11.6707 29.9986V29.9023Z"
        fill="white"
      />
      <rect
        x="4.69336"
        y="16.6689"
        width="2.40601"
        height="2.52632"
        fill="#148A64"
      />
      <path
        d="M7.09814 16.6689L7.09814 25.3306L7.0019 25.3306C6.19341 25.3306 5.78916 25.3306 5.48035 25.1733C5.20872 25.0349 4.98788 24.814 4.84947 24.5424C4.69213 24.2336 4.69213 23.8293 4.69213 23.0208L4.69213 18.9787C4.69213 18.1702 4.69213 17.766 4.84947 17.4572C4.98788 17.1855 5.20872 16.9647 5.48035 16.8263C5.78916 16.6689 6.19341 16.6689 7.0019 16.6689L7.09814 16.6689Z"
        fill="white"
      />
      <rect
        x="24.9031"
        y="22.8044"
        width="2.40601"
        height="2.52632"
        fill="#148A64"
      />
      <rect
        x="24.9031"
        y="16.7893"
        width="2.40601"
        height="2.52632"
        fill="#146053"
      />
      <path
        d="M24.9981 16.6689C25.8066 16.6689 26.2108 16.6689 26.5196 16.8263C26.7913 16.9647 27.0121 17.1855 27.1505 17.4572C27.3079 17.766 27.3079 18.1702 27.3079 18.9787L27.3079 23.0208C27.3079 23.8293 27.3079 24.2336 27.1505 24.5424C27.0121 24.814 26.7913 25.0349 26.5196 25.1733C26.2108 25.3306 25.8066 25.3306 24.9981 25.3306L24.9018 25.3306L24.9018 16.6689L24.9981 16.6689Z"
        fill="white"
      />
      <rect
        x="20.3306"
        y="9.21021"
        width="6.97744"
        height="7.57895"
        fill="#146053"
      />
      <rect
        x="4.69336"
        y="9.21021"
        width="15.6391"
        height="0.481203"
        fill="#146053"
      />
      <rect
        x="4.69312"
        y="9.69165"
        width="6.97744"
        height="6.97744"
        fill="#148A64"
      />
      <rect
        x="20.3303"
        y="25.3311"
        width="6.97744"
        height="6.97744"
        fill="#148A64"
      />
    </svg>
  )
}

export default Mediconnect
