import {
  DropdownArrow,
  DropdownList,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {StyledContainer} from './styles'
import {LabelDropdownProps} from './types'
import useLabelDropdown from './hook'

const LabelDropdown = (props: LabelDropdownProps) => {
  const {
    label = '',
    value = '',
    options = [],
    placeholder = 'select',
    isMandatory = false,
  } = props || {}

  const {isOpen, toggleDropdown, closeDropdown, handleOptionChange} =
    useLabelDropdown(props)

  return (
    <StyledContainer isOpen={isOpen}>
      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="label"
      >
        {label}
        {isMandatory && '*'}
      </Typography>
      <div className="inputContainer" onClick={toggleDropdown}>
        <input
          type="text"
          value={value}
          readOnly
          placeholder={placeholder}
          onBlur={closeDropdown}
        />
        <div className="dropdownArrow">
          <DropdownArrow />
        </div>
      </div>
      {isOpen && (
        <DropdownList
          suggestions={options}
          selectedSuggestions={[value]}
          handleSelection={handleOptionChange}
        />
      )}
    </StyledContainer>
  )
}

export default LabelDropdown
