import {StyledInfoBannerWrapper} from './styles'
import {Typography, TypographyVariants} from '../../atomic'
import {InfoBannerProps} from '@mosaic-wellness/fe-types'

const InfoBanner = (props: InfoBannerProps) => {
  const {title = '', subTitle = '', variant = 'warning'} = props

  return (
    <StyledInfoBannerWrapper variant={variant}>
      <Typography variant={TypographyVariants.TAG_BOLD} customClassName="text">
        {title}
      </Typography>
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="text"
      >
        {subTitle}
      </Typography>
    </StyledInfoBannerWrapper>
  )
}

export default InfoBanner
