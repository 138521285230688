import styled from 'styled-components'

export const StyleContainer = styled.div`
  .titleWrapper {
    color: var(--brand-light-secondary-text-color) !important;
  }
  .headerWrapper {
  }
  .subTitleText {
  }

  .banner {
    padding: var(--dls-size-16);
  }

  .accordian_container {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    padding: var(--dls-size-16);
  }

  .extendedHeader {
    border-bottom: var(--dls-size-1) solid var(--dls-divider-light-color);
    background: var(--dls-backgound-color-100);
    padding: var(--dls-size-8) var(--dls-size-16) !important;
  }

  .load_more_cta {
    display: flex;
    justify-content: center;
    padding: var(--dls-size-16) var(--dls-size-0);
    cursor: pointer;
    gap: var(--dls-size-8);

    .load_more_text {
      color: var(--dls-brand-primary-color-500);
    }
  }

  .accordion_container {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-24);
  }

  .empty_wallet_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--dls-size-24);
    padding: var(--dls-size-120) var(--dls-size-96);
    text-align: center;
  }

  .empty_wallet_text {
    color: var(--dls-secondary-text-colors);
  }
`
