import styled from 'styled-components'

export const RxHabitInstructionsWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--dls-size-8);
  border-bottom-left-radius: var(--dls-size-4);
  border-bottom-right-radius: var(--dls-size-4);
  .instruction-wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--dls-size-8);
    .instruction-icon {
      border-radius: var(--dls-size-4);
      width: 36px;
      height: 36px;
    }
    .secondary-text {
      color: var(--brand-light-secondary-text-color);
    }
    .content-container {
      display: flex;
      flex-direction: column;
    }
  }
`
