import {IconProps} from '../types'

const RatingStar = ({
  width = 16,
  height = 16,
  fill = '#F6CA4C',
  stroke = '#9E9E9E',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8934_24727)">
        <path
          d="M8.00151 11.5135L10.7682 13.1868C11.2748 13.4935 11.8948 13.0402 11.7615 12.4668L11.0282 9.32017L13.4748 7.20017C13.9215 6.8135 13.6815 6.08017 13.0948 6.0335L9.87485 5.76017L8.61485 2.78684C8.38818 2.24684 7.61485 2.24684 7.38818 2.78684L6.12818 5.7535L2.90818 6.02684C2.32151 6.0735 2.08151 6.80684 2.52818 7.1935L4.97485 9.3135L4.24151 12.4602C4.10818 13.0335 4.72818 13.4868 5.23485 13.1802L8.00151 11.5135Z"
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_8934_24727">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RatingStar
