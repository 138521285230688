import {useState} from 'react'
import {LabelDropdownProps} from './types'
import noop from 'lodash/noop'
import {DropdownOption} from '@mosaic-wellness/fe-types'

const useLabelDropdown = (props: LabelDropdownProps) => {
  const {onChange = noop} = props

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const handleOptionChange = (option: DropdownOption) => {
    closeDropdown()
    onChange(option.value)
  }
  return {
    isOpen,
    toggleDropdown,
    closeDropdown,
    handleOptionChange,
  }
}

export default useLabelDropdown
