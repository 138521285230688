import styled from 'styled-components'
export const WalletTransactionSummaryContainer = styled.div`
  display: flex;
  background-color: var(--dls-brand-secondary-color-200);
  border-radius: var(--dls-size-0) var(--dls-size-0) var(--dls-size-16)
    var(--dls-size-16);
  padding: var(--dls-size-16) 0;
  border-bottom: var(--dls-size-2) solid var(--dls-divider-light-color);
  .divider {
    width: var(--dls-size-2);
    height: var(--dls-size-36);
    background: var(--brand-primary-light-divider-color);
  }
`

export const TransactionSummaryContainer = styled.div<{
  amountColor: string
}>`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-8);
  flex: 1;
  margin-left: var(--dls-size-16);

  .transaction_header {
    display: flex;
    flex-direction: row;
    gap: var(--dls-size-8);
  }

  .wallet_summary_text_container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: var(--dls-size-8);

    .amount {
      line-height: normal;
      color: ${({amountColor}) =>
        amountColor || `var(--dls-primary-text-colors)`};
    }
  }
`
