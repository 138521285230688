import styled from 'styled-components'

export const StyledRxPatientDetailsWrapper = styled.div`
  background: var(--brand-primary-white-color);
  padding: 13px 10px 16px 10px;
  .flex-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--dls-size-4);
  }
  .secondary-text {
    color: var(--brand-light-secondary-text-color);
  }
  .gender-icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .divider {
    border-radius: 50%;
    height: 2px;
    width: 2px;
    background: var(--brand-light-secondary-text-color);
  }
`
