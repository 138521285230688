const VideoCallActive = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#DF555B" />
      <path
        d="M30 16.9314C30 16.3256 30 16.0226 29.8802 15.8824C29.7763 15.7607 29.6203 15.6961 29.4608 15.7086C29.2769 15.7231 29.0627 15.9373 28.6343 16.3657L25 20L28.6343 23.6343C29.0627 24.0627 29.2769 24.2769 29.4608 24.2914C29.6203 24.3039 29.7763 24.2393 29.8802 24.1176C30 23.9774 30 23.6744 30 23.0686V16.9314Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 17.8C10 16.1198 10 15.2798 10.327 14.638C10.6146 14.0735 11.0735 13.6146 11.638 13.327C12.2798 13 13.1198 13 14.8 13H20.2C21.8802 13 22.7202 13 23.362 13.327C23.9265 13.6146 24.3854 14.0735 24.673 14.638C25 15.2798 25 16.1198 25 17.8V22.2C25 23.8802 25 24.7202 24.673 25.362C24.3854 25.9265 23.9265 26.3854 23.362 26.673C22.7202 27 21.8802 27 20.2 27H14.8C13.1198 27 12.2798 27 11.638 26.673C11.0735 26.3854 10.6146 25.9265 10.327 25.362C10 24.7202 10 23.8802 10 22.2V17.8Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default VideoCallActive
