import {TextRow} from './styles'
import {Typography, TypographyVariants} from '../../../atomic'
import {getVariantAndStyles} from '../utils'
import {ConsultPaymentSummaryListItemData} from '@mosaic-wellness/fe-types'

const TextItem = (props: ConsultPaymentSummaryListItemData) => {
  const {
    label = '',
    value = '',
    labelType = '',
    valueType = '',
    subtext = '',
  } = props

  const [labelVariant, labelStyle] = getVariantAndStyles(labelType)
  const [valueVariant, valueStyle] = getVariantAndStyles(valueType)

  return (
    <>
      <TextRow>
        <Typography variant={labelVariant} customClassName={labelStyle}>
          {label}
        </Typography>
        <Typography variant={valueVariant} customClassName={valueStyle}>
          {value}
        </Typography>
      </TextRow>
      <TextRow>
        {subtext && (
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="secondary-text subText"
          >
            <span dangerouslySetInnerHTML={{__html: subtext}} />
          </Typography>
        )}
      </TextRow>
    </>
  )
}

export default TextItem
