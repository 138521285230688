import {Spacer, Typography, TypographyVariants} from '../../atomic'
import {RatingStarSelector} from '../../molecules'
import {StyledConsultRatingStarSelector} from './styles'
import {RatingFeedbackProps} from '@mosaic-wellness/fe-types'

function ConsultRatingStarSelector(props: RatingFeedbackProps) {
  const {title = '', subTitle = ''} = props || {}

  return (
    <StyledConsultRatingStarSelector>
      <div className="consult-rating-star-wrapper">
        <Typography
          variant={TypographyVariants.SUBHEADING_BOLD}
          customClassName="title"
        >
          <span dangerouslySetInnerHTML={{__html: title}} />
        </Typography>
        <Spacer variant="small" />
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="label"
        >
          <span dangerouslySetInnerHTML={{__html: subTitle}} />
        </Typography>
        <div className="divider" />
        <div className="star-wrapper">
          <RatingStarSelector
            {...props}
            height={48}
            width={48}
            gap="var(--dls-size-8)"
          />
        </div>
      </div>
    </StyledConsultRatingStarSelector>
  )
}

export default ConsultRatingStarSelector
