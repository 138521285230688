import React from 'react'
import {WalletTransactionCardContainer} from './styles'
import {IWalletTransactionCard} from './types'
import {OptimizedImage, Typography, TypographyVariants} from '../../atomic'
import isEmpty from 'lodash/isEmpty'

const WalletTransactionCard = (props: IWalletTransactionCard) => {
  const {state} = props || {}
  const {transaction} = state || {}

  const {
    icon = '',
    title = '',
    tag = '',
    subtitle = '',
    subtitle2 = '',
    subtitle2Color = '',
    amount = '',
    amountColor = '',
  } = transaction || {}

  if (isEmpty(title || amount)) {
    return null
  }

  return (
    <WalletTransactionCardContainer
      amountColor={amountColor}
      subtitle2Color={subtitle2Color}
    >
      <div className="image_and_text_container">
        <div className="image_container">
          <OptimizedImage source={icon} aspectWidth={20} aspectHeight={20} />
        </div>
        <div className="text_container">
          <div className="subtitle_text_container">
            <Typography
              variant={TypographyVariants.BODY_BASE_REGULAR}
              customClassName="subtitle_pill_text"
            >
              {title}
            </Typography>
            {tag && (
              <div className="subtitle_pill">
                <Typography
                  variant={TypographyVariants.X_SMALL}
                  customClassName="subtitle_pill_text"
                >
                  {tag}
                </Typography>
              </div>
            )}
          </div>
          <div className="subtitle_and_subtitle2_container">
            <Typography
              variant={TypographyVariants.X_SMALL}
              customClassName="text"
            >
              {subtitle}
            </Typography>
            {subtitle2 && (
              <div className="dot_and_pill_text">
                <div className="dot" />
                <Typography
                  variant={TypographyVariants.X_SMALL}
                  customClassName="dot_text"
                >
                  {subtitle2}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="amount_container">
        <Typography
          variant={TypographyVariants.BODY_BASE_BOLD}
          customClassName="amount_text"
        >
          {amount}
        </Typography>
      </div>
    </WalletTransactionCardContainer>
  )
}

export default WalletTransactionCard
