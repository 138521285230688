const ThumbsDown = ({
  fill = '#F9E3E4',
  stroke = '#DE545B',
  strokeWidth = '1.2',
  width = '24',
  height = '24',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="thumbs-down">
      <path
        id="Icon"
        d="M17 2V13M22 9.8V5.2C22 4.07989 22 3.51984 21.782 3.09202C21.5903 2.71569 21.2843 2.40973 20.908 2.21799C20.4802 2 19.9201 2 18.8 2H8.11802C6.65654 2 5.92579 2 5.33559 2.26743C4.81541 2.50314 4.37331 2.88242 4.06125 3.36072C3.70718 3.90339 3.59606 4.62564 3.37383 6.07012L2.85076 9.47012C2.55765 11.3753 2.4111 12.3279 2.69381 13.0691C2.94195 13.7197 3.40866 14.2637 4.01393 14.6079C4.70354 15 5.66734 15 7.59494 15H8.40001C8.96006 15 9.24009 15 9.454 15.109C9.64216 15.2049 9.79514 15.3578 9.89101 15.546C10 15.7599 10 16.0399 10 16.6V19.5342C10 20.896 11.104 22 12.4658 22C12.7907 22 13.085 21.8087 13.217 21.5119L16.5777 13.9502C16.7306 13.6062 16.807 13.4343 16.9278 13.3082C17.0346 13.1967 17.1657 13.1115 17.311 13.0592C17.4753 13 17.6634 13 18.0398 13H18.8C19.9201 13 20.4802 13 20.908 12.782C21.2843 12.5903 21.5903 12.2843 21.782 11.908C22 11.4802 22 10.9201 22 9.8Z"
        stroke={stroke}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
)

export default ThumbsDown
