const AccordionRight = ({
  width = 24,
  height = 24,
  stroke = '#121212',
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="accordion-right"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AccordionRight
