import {StyledRatingFeedback} from './styles'
import {Typography, TypographyVariants} from '../../atomic'
import {RatingFeedbackProps} from '@mosaic-wellness/fe-types'
import RatingStarSelector from '../RatingStarSelector'

function RatingFeedback(props: RatingFeedbackProps) {
  const {title = '', subTitle = ''} = props || {}

  return (
    <StyledRatingFeedback>
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="label"
      >
        {title}
      </Typography>
      <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
        {subTitle}
      </Typography>
      <div className="divider" />
      <RatingStarSelector {...props} />
    </StyledRatingFeedback>
  )
}

export default RatingFeedback
