import {StyledImage} from './styles'
import {ImageProps} from './types'

export const Image = ({
  src = '',
  customClassName = '',
  alt = 'image',
  ...restProps
}: ImageProps) => {
  if (!src) {
    return null
  }

  return (
    <StyledImage
      src={src}
      alt={alt}
      className={customClassName}
      {...restProps}
    />
  )
}

export default Image
