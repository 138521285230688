import styled from 'styled-components'

export const StyledConsultPaymentSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--dls-size-16);
  padding-top: var(--dls-size-16);
  .body {
    width: 100%;
    padding: 0 var(--dls-size-16);
  }
  .pending {
    padding: var(--dls-size-16);
    background-color: var(--dls-error-color-100);
  }
  .pending-amount {
    padding: var(--dls-size-16);
    background-color: var(--dls-warning-color-100);
  }
  .amount-paid {
    padding: var(--dls-size-16);
    background-color: var(--dls-success-color-100);
  }
`

export const StyledDivider = styled.div`
  width: 100%;
  border-bottom: var(--dls-size-1) dashed
    var(--brand-primary-light-divider-color);
`
