import {useEffect, useRef, useState, useMemo} from 'react'
import {UseSliderStateProps} from './types'

export const getImageDimensionFromAspectRatioFor360 = ({
  aspectRatio = 1,
  noOfSlides = 1,
  skipPaddings = false,
}) => {
  const availableWidth =
    360 - (skipPaddings ? 0 : 16 + Math.floor(noOfSlides) * 8) // 360 viewport & rest padding
  const slideWidth = availableWidth / noOfSlides
  const slideHeight = slideWidth / aspectRatio
  return [slideWidth, slideHeight]
}

export const useGenericSlider = ({
  noOfSlides,
  noOfSlidesDesktop,
  aspectRatio,
  startFromSlide,
  onSlideChange,
  isMobile,
}: UseSliderStateProps) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isEndOfScroll, setIsEndOfScroll] = useState(false)

  const imageDimensions: number[] = useMemo(() => {
    return getImageDimensionFromAspectRatioFor360({
      aspectRatio: aspectRatio || 1,
      noOfSlides:
        (isMobile ? noOfSlides : noOfSlidesDesktop || noOfSlides) || 1,
    })
  }, [isMobile, aspectRatio, noOfSlides, noOfSlidesDesktop])

  useEffect(() => {
    if (sliderRef.current && startFromSlide) {
      const slideWidth = sliderRef.current.children[0].clientWidth
      sliderRef.current.scrollLeft = slideWidth * startFromSlide
      onSlideChange(startFromSlide) // Set the active slide to the second slide
    }
  }, [startFromSlide, imageDimensions, onSlideChange])

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current && sliderRef.current.children.length > 0) {
        const slideWidth = sliderRef.current.children[0].clientWidth
        const scrollPosition = sliderRef.current.scrollLeft
        const scrollableWidth =
          sliderRef.current.scrollWidth - sliderRef.current.clientWidth

        const newIndex = Math.round(scrollPosition / slideWidth)

        setCurrentSlide(newIndex)
        onSlideChange(newIndex)

        setIsEndOfScroll(scrollPosition + slideWidth / 10 >= scrollableWidth) // to correct hide scroll button as scroll position isn't completely covered even if last slide is show by some decimal point
      }
    }

    const currentSlider = sliderRef.current
    currentSlider?.addEventListener('scroll', handleScroll)
    return () => {
      currentSlider?.removeEventListener('scroll', handleScroll)
    }
  }, [imageDimensions, onSlideChange])

  const handleNextClick = () => {
    if (sliderRef.current) {
      const slideWidth = sliderRef.current.children[0].clientWidth
      sliderRef.current.scrollBy({left: slideWidth, behavior: 'smooth'})
    }
  }

  const handlePrevClick = () => {
    if (sliderRef.current) {
      const slideWidth = sliderRef.current.children[0].clientWidth
      sliderRef.current.scrollBy({left: -slideWidth, behavior: 'smooth'})
    }
  }

  return {
    sliderRef,
    noOfSlidesUsed:
      (isMobile ? noOfSlides : noOfSlidesDesktop || noOfSlides) || 1,
    currentSlide,
    isEndOfScroll,
    imageDimensions,
    handleNextClick,
    handlePrevClick,
  }
}
