import styled from 'styled-components'

const StyledCTACircle = styled.button<{disabled?: boolean}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: var(--brand-light-bg-color);
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  border: 0px solid transparent;
  height: 40px;
  width: 40px;
  cursor: pointer;
`

export {StyledCTACircle}
