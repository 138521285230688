const DropdownArrow = ({
  width = 17,
  height = 17,
  stroke = '#9E9E9E',
  isClosed = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        isClosed
          ? {
              transform: 'rotate(180deg)',
            }
          : {}
      }
    >
      <path
        d="M12.5 10.5L8.5 6.5L4.5 10.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropdownArrow
