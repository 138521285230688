const EmptyProducts = ({width = 100, height = 100}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
    >
      <g opacity="0.6">
        <path
          d="M92.1875 32.8125H7.8125C6.94956 32.8125 6.25 33.5121 6.25 34.375V40.625C6.25 41.4879 6.94956 42.1875 7.8125 42.1875H92.1875C93.0504 42.1875 93.75 41.4879 93.75 40.625V34.375C93.75 33.5121 93.0504 32.8125 92.1875 32.8125Z"
          fill="#8D8D8D"
        />
        <path
          d="M81.5469 89.0625H18.4531C16.8438 89.0625 15.5 87.8438 15.3437 86.25L10.9375 42.1875H89.0625L84.6562 86.25C84.5 87.8438 83.1562 89.0625 81.5469 89.0625Z"
          fill="#7D7D7D"
        />
        <path
          d="M49.9844 81.25C49.125 81.25 48.4219 80.5469 48.4219 79.6875V51.5625C48.4219 50.7031 49.125 50 49.9844 50C50.8437 50 51.5469 50.7031 51.5469 51.5625V79.6875C51.5469 80.5469 50.8437 81.25 49.9844 81.25ZM39.0625 81.25C38.2031 81.25 37.5 80.5469 37.5 79.6875V51.5625C37.5 50.7031 38.2031 50 39.0625 50C39.9219 50 40.625 50.7031 40.625 51.5625V79.6875C40.625 80.5469 39.9219 81.25 39.0625 81.25ZM28.1406 81.25C27.2812 81.25 26.5781 80.5469 26.5781 79.6875V51.5625C26.5781 50.7031 27.2812 50 28.1406 50C29 50 29.7031 50.7031 29.7031 51.5625V79.6875C29.7031 80.5469 29 81.25 28.1406 81.25ZM60.9375 81.25C60.0781 81.25 59.375 80.5469 59.375 79.6875V51.5625C59.375 50.7031 60.0781 50 60.9375 50C61.7969 50 62.5 50.7031 62.5 51.5625V79.6875C62.5 80.5469 61.7969 81.25 60.9375 81.25ZM71.8594 81.25C71 81.25 70.2969 80.5469 70.2969 79.6875V51.5625C70.2969 50.7031 71 50 71.8594 50C72.7188 50 73.4219 50.7031 73.4219 51.5625V79.6875C73.4219 80.5469 72.7188 81.25 71.8594 81.25Z"
          fill="#DADADA"
        />
        <path
          d="M40.6739 39.2792L5.95053 68.4156C4.62842 69.525 4.45597 71.4961 5.56535 72.8182C6.67473 74.1403 8.64584 74.3128 9.96795 73.2034L44.6913 44.067C46.0134 42.9576 46.1859 40.9865 45.0765 39.6644C43.9671 38.3423 41.996 38.1699 40.6739 39.2792Z"
          fill="#B9B9B9"
        />
        <path
          d="M59.3185 39.1968C57.9968 38.087 56.0256 38.2587 54.9158 39.5804C53.806 40.9022 53.9777 42.8733 55.2994 43.9832L90.0126 73.1317C91.3343 74.2415 93.3055 74.0697 94.4154 72.748C95.5252 71.4263 95.3534 69.4551 94.0317 68.3453L59.3185 39.1968Z"
          fill="#B9B9B9"
        />
        <path
          d="M49.9993 21.875C49.1399 21.875 48.4368 21.1719 48.4368 20.3125V10.9375C48.4368 10.0781 49.1399 9.375 49.9993 9.375C50.8586 9.375 51.5618 10.0781 51.5618 10.9375V20.3125C51.5618 21.1719 50.8586 21.875 49.9993 21.875ZM37.843 26.9062C37.4367 26.9062 37.0461 26.75 36.7336 26.4531L30.1086 19.8281C29.8165 19.536 29.6523 19.1397 29.6523 18.7266C29.6523 18.3134 29.8165 17.9172 30.1086 17.625C30.4008 17.3328 30.797 17.1687 31.2102 17.1687C31.6234 17.1687 32.0196 17.3328 32.3118 17.625L38.9368 24.25C39.1548 24.4682 39.3031 24.7463 39.3628 25.0489C39.4224 25.3516 39.3908 25.6651 39.2719 25.9498C39.1531 26.2344 38.9523 26.4773 38.6951 26.6476C38.4379 26.8179 38.1358 26.9079 37.8274 26.9062H37.843Z"
          fill="#DADADA"
        />
        <path
          d="M50 21.875C49.1406 21.875 48.4375 21.1719 48.4375 20.3125V10.9375C48.4375 10.0781 49.1406 9.375 50 9.375C50.8594 9.375 51.5625 10.0781 51.5625 10.9375V20.3125C51.5625 21.1719 50.8594 21.875 50 21.875ZM62.1562 26.9062C61.75 26.9062 61.3594 26.75 61.0469 26.4531C60.902 26.3086 60.7871 26.1369 60.7087 25.9479C60.6303 25.7588 60.5899 25.5562 60.5899 25.3516C60.5899 25.1469 60.6303 24.9443 60.7087 24.7553C60.7871 24.5663 60.902 24.3946 61.0469 24.25L67.6719 17.625C67.964 17.3328 68.3603 17.1687 68.7734 17.1687C69.1866 17.1687 69.5828 17.3328 69.875 17.625C70.1672 17.9172 70.3313 18.3134 70.3313 18.7266C70.3313 19.1397 70.1672 19.536 69.875 19.8281L63.25 26.4531C62.9375 26.7656 62.5469 26.9062 62.1406 26.9062H62.1562Z"
          fill="#DADADA"
        />
      </g>
    </svg>
  )
}

export default EmptyProducts
