import {PreviewProductHeaderData} from '@mosaic-wellness/fe-types'
import {
  OptimizedImage,
  RatingStar,
  Spacer,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {StyledConcern, StyledPreviewProductHeaderWrapper} from './styles'

const PreviewProductHeader = (props: PreviewProductHeaderData) => {
  const {
    name = '',
    productImageUrl = '',
    productFor = '',
    discountText = '',
    reviews = '',
    rating = '',
    unitsSold = '',
    discountedPrice = '',
    actualPrice = '',
    currency = '',
    concern = '',
  } = props

  return (
    <div>
      <StyledPreviewProductHeaderWrapper>
        <div className="image-container">
          <OptimizedImage
            source={productImageUrl}
            customClassName="product-image"
            data-testid="product-image"
          />
        </div>

        <div className="info-container">
          <Typography
            variant={TypographyVariants.CTA_LABEL_BIG}
            customClassName="name"
          >
            {name}
          </Typography>
          {productFor && (
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="product-for"
            >
              For: <span>{productFor}</span>
            </Typography>
          )}
          <div className="price-section">
            <Typography variant={TypographyVariants.CTA_LABEL_BIG}>
              {currency}
              {discountedPrice || actualPrice}
            </Typography>

            {!!discountedPrice && (
              <Typography
                variant={TypographyVariants.BODY_BASE_BOLD}
                customClassName="discount-price"
              >
                {currency}
                {actualPrice}
              </Typography>
            )}
            {discountText && (
              <div className="discount_text">
                <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {discountText}
                </Typography>
              </div>
            )}
          </div>
          <div className="rating-section">
            {parseFloat(reviews) > 0 && (
              <>
                <div className="rating-section__rating_container">
                  <Typography variant={TypographyVariants.TAG_REGULAR}>
                    {rating}
                  </Typography>
                  <RatingStar />
                </div>
                <div className="rating-section__divider"></div>
                <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {reviews} Reviews
                </Typography>
                {!!unitsSold && <div className="rating-section__divider"></div>}
              </>
            )}
            {!!unitsSold && (
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {unitsSold.toLocaleString('en-IN')} Units Sold
              </Typography>
            )}
          </div>
        </div>
      </StyledPreviewProductHeaderWrapper>
      {concern && (
        <>
          <Spacer variant="medium" />
          <StyledConcern>
            <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
              {concern}
            </Typography>
          </StyledConcern>
        </>
      )}
    </div>
  )
}

export default PreviewProductHeader
