import React from 'react'

type DeviceTypeContext = {
  isMobile: boolean
}
const DEFAULT_DEVICE_TYPE_CONTEXT: DeviceTypeContext = {
  isMobile: true,
}

export const DEVICE_TYPE_CONTEXT = React.createContext<DeviceTypeContext>(
  DEFAULT_DEVICE_TYPE_CONTEXT
)

export const useDeviceTypeContext = () => {
  const context = React.useContext(DEVICE_TYPE_CONTEXT)

  if (!context) {
    throw new Error(
      'deviceTypeContext has not been initialized with a provider'
    )
  }

  return {
    isMobile: context.isMobile,
  } as const
}
