import styled, {css} from 'styled-components'

export const StyledConsultRatingReason = styled.div`
  padding: var(--dls-size-16);
  padding-top: 20px;
  @media (min-width: 768px) {
    padding-top: 18px;
  }

  .title {
    @media (min-width: 768px) {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .label {
    color: var(--brand-light-secondary-text-color);
  }
  .divider {
    height: var(--dls-size-16);
    @media (min-width: 768px) {
      height: 10px;
    }
  }
  .options-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--dls-size-8);
    row-gap: var(--dls-size-8);
  }
`

export const StyledReasonOption = styled.div<{isSelected: boolean}>`
  padding: 6px var(--dls-size-8);
  border-radius: var(--dls-size-4);
  border: var(--dls-size-1) solid var(--brand-primary-light-divider-color);
  cursor: pointer;
  .label {
    color: var(--brand-light-secondary-text-color);
  }
  ${({isSelected}) =>
    isSelected &&
    css`
      border: var(--dls-size-1) solid var(--dls-brand-primary-color-500);
      background: var(--dls-brand-primary-color-100);
      .label {
        color: var(--dls-brand-primary-color-500);
      }
    `}
`
