import React, {useCallback, useEffect} from 'react'
import {ExpandableSection} from '../../molecules'
import {OptimizedImage, Typography, TypographyVariants} from '../../atomic'
import WalletTransactionCard from '../WalletTransactionCard'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import {Spinner} from 'src/components/shared/src/ComponentsV2/common'
import {ITimeLine} from './types'

const TimeLine = (props: ITimeLine) => {
  const {state, actions} = props || {}
  const {timeline, isTransactionLoading = false, loadMoreCta} = state || {}
  const {
    title = '',
    amount = '',
    accordian = false,
    month = 0,
    year = 0,
    transactions = [],
    subTitleAmountColor = '',
  } = timeline || {}

  const {label = '', icon = ''} = loadMoreCta || {}

  const {onLoadTransactions = noop} = actions || {}

  const handleAccordionCollapsed = useCallback(() => {
    if (transactions?.length > 0) return
    onLoadTransactions({
      month,
      offset: 0, // assigning 0 initially if there is no data for specific month so we can get the 1st page/offset transaction on accordion click
      year,
      sectionKey: title,
    })
  }, [month, onLoadTransactions, title, transactions?.length, year])

  const handleLoadMoreTransaction = useCallback(
    (event) => {
      event.stopPropagation()
      onLoadTransactions({
        month: timeline.month,
        offset: timeline.nextOffset,
        year: timeline.year,
        sectionKey: title,
      })
    },
    [
      onLoadTransactions,
      timeline.month,
      timeline.nextOffset,
      timeline.year,
      title,
    ]
  )

  if (isEmpty(title)) {
    return null
  }

  return (
    <div onClick={handleAccordionCollapsed}>
      <ExpandableSection
        key={title}
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customTitleClassName="titleWrapper"
        title={title}
        subTitle={amount}
        customSubTitleClassName="subTitleText"
        wrapperClassName="headerWrapper"
        openByDefault={accordian}
        headerClassName="extendedHeader"
        isCollapsible={true}
        subTitleAmountColor={subTitleAmountColor}
      >
        <>
          <div className="accordian_container">
            {transactions?.map((transaction) => (
              <WalletTransactionCard
                key={transaction?.code}
                state={{
                  transaction,
                }}
              />
            ))}
          </div>

          {timeline.showLoadMore && (
            <>
              {isTransactionLoading[title] ? (
                <Spinner size={20} color="var(--dls-brand-primary-color-500)" />
              ) : (
                <div
                  className="load_more_cta"
                  onClick={handleLoadMoreTransaction}
                >
                  <Typography
                    variant={TypographyVariants.CTA_LABEL_SMALL}
                    customClassName="load_more_text"
                  >
                    {label}
                  </Typography>
                  <OptimizedImage
                    source={icon}
                    aspectWidth="16"
                    aspectHeight="15"
                    desktopViewWidth="SMALL"
                    mobileViewWidth="SMALL"
                    altText="load more"
                    loading="lazy"
                  />
                </div>
              )}
            </>
          )}
        </>
      </ExpandableSection>
    </div>
  )
}

export default TimeLine
