import styled from 'styled-components'

const TabsContainer = styled.div<{isApp: boolean}>`
  display: flex;
  border-bottom: 1px solid var(--dls-divider-light-color);
  position: sticky;
  top: ${({isApp = false}) => (isApp ? '0px' : '60px')};
  z-index: 5;
  background-color: white;
  @media (max-width: 480px) {
    top: ${({isApp = false}) => (isApp ? '0px' : '50px')};
  }
`

const Tab: any = styled.div<{isActive: boolean}>`
  padding: 12px;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({isActive}: {isActive: boolean}) =>
    isActive
      ? `
    border-bottom: 3px solid var(--dls-brand-primary-color-500);
    color: var(--dls-brand-primary-color-500);
  `
      : `
    border-bottom: 3px solid transparent;
          color: var(--brand-light-secondary-text-color);

`}
`

const TabContent = styled.div`
  height: 100%;
  width: 100%;
`

export {Tab, TabContent, TabsContainer}
