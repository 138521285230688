import noop from 'lodash/noop'
import {StyledCTACircle} from './styles'
import {CTACircleProps} from './types'

const CTACircle = (props: CTACircleProps) => {
  const {onClick = noop, children, disabled} = props

  return (
    <StyledCTACircle onClick={onClick} disabled={disabled}>
      {children}
    </StyledCTACircle>
  )
}

export default CTACircle
