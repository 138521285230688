import styled from 'styled-components'

export const StyledConsultRatingStarSelector = styled.div`
  padding: var(--dls-size-2);
  background: var(--brand-primary-white-color);
  border-top-left-radius: var(--dls-size-16);
  border-top-right-radius: var(--dls-size-16);
  overflow: hidden;
  .consult-rating-star-wrapper {
    padding: 14px;
    border-top-left-radius: var(--dls-size-16);
    border-top-right-radius: var(--dls-size-16);
    background: linear-gradient(180deg, #fafaf6 0%, #fff 100%);
  }
  .title {
    @media (min-width: 768px) {
      font-size: 22px;
      font-weight: 700;
    }
  }
  .label {
    color: var(--brand-light-secondary-text-color);
  }
  .divider {
    height: var(--dls-size-16);
    @media (min-width: 768px) {
      height: 18px;
    }
  }
  .star-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`
