import styled, {css} from 'styled-components'

export const MenuRowItemWrapper = styled.div<{hideRightArrow: boolean}>`
  padding: var(--dls-size-16);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--dls-size-8);
  cursor: pointer;

  ${({hideRightArrow}) =>
    hideRightArrow &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  .details {
    width: 100%;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
`
