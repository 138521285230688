import React from 'react'
import {OptimizedImage} from '../../atomic/OptimizedImage'
import {Typography, TypographyVariants} from '../../atomic'
import {IEmptyWallet} from './types'

const EmptyWallet = (props: IEmptyWallet) => {
  const {state} = props || {}
  const {emptyBanner} = state || {}
  const {image = '', text = ''} = emptyBanner || {}

  return (
    <div className="empty_wallet_container">
      <OptimizedImage
        source={image}
        aspectWidth="180"
        aspectHeight="180"
        desktopViewWidth="SMALL"
        mobileViewWidth="SMALL"
        altText="empty-wallet"
        loading="lazy"
      />
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="empty_wallet_text"
      >
        {text}
      </Typography>
    </div>
  )
}

export default EmptyWallet

//
