import noop from 'lodash/noop'
import {DropdownContainer, DropdownItem} from './styles'
import {DropdownListProps} from './types'
import {DropdownOption} from '@mosaic-wellness/fe-types'
import {useCallback} from 'react'
import Typography from '../Typography'
import {TypographyVariants} from '../Typography/types'
import {CheckBox} from '../Icons'
import RadioButton from '../RadioButton'

const DropdownList = (props: DropdownListProps) => {
  const {
    suggestions = [],
    selectedSuggestions = [],
    handleSelection = noop,
    multiSelect = false,
  } = props || {}

  const renderSuggestion = useCallback(
    (suggestion: DropdownOption, index: number) => {
      const selectedSuggestion = selectedSuggestions.includes(suggestion.value)

      return (
        <DropdownItem
          key={'suggestion_' + index}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => handleSelection(suggestion)}
        >
          <Typography
            variant={TypographyVariants.X_SMALL}
            customClassName="label"
          >
            {suggestion.label}
          </Typography>
          {multiSelect ? (
            <CheckBox isChecked={selectedSuggestion} />
          ) : (
            <RadioButton selected={selectedSuggestion} />
          )}
        </DropdownItem>
      )
    },
    [handleSelection, multiSelect, selectedSuggestions]
  )

  if (!suggestions?.length) {
    return null
  }

  return (
    <DropdownContainer>{suggestions.map(renderSuggestion)}</DropdownContainer>
  )
}

export default DropdownList
