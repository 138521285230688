import {Typography, TypographyVariants} from '../../atomic'
import {StyledContainer, Input} from './styles'
import {LabelTextInputProps} from './types'

const LabelTextInput = (props: LabelTextInputProps) => {
  const {label, showNumberControl = false, isMandatory = false} = props || {}

  return (
    <StyledContainer>
      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="label"
      >
        {label}
        {isMandatory && '*'}
      </Typography>
      <Input {...props} showNumberControl={showNumberControl} />
    </StyledContainer>
  )
}

export default LabelTextInput
