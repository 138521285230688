import styled from 'styled-components'

export const BannerWithTextAndCtaContainer = styled.div<{
  backgroundColor: string
  ctaBorderColor: string
  ctaTextColor: string
}>`
  display: flex;
  border-radius: var(--dls-size-8);
  background-color: ${({backgroundColor}) =>
    backgroundColor || `var(--dls-success-color-100)`};
  padding: 9px var(--dls-size-8);
  justify-content: space-between;

  .banner_image_text_container {
    display: flex;
    gap: var(--dls-size-8);
    align-items: center;

    .title_subtitle_container {
      display: flex;
      flex-direction: column;
      gap: var(--dls-size-4);
    }
  }

  .text {
    color: var(--brand-primary-text-color);
  }

  .cta_container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .cta {
      display: flex;
      border-radius: var(--dls-size-16);
      padding: var(--dls-size-8) var(--dls-size-16);
      border: var(--dls-size-1) solid
        ${({ctaBorderColor}) =>
          ctaBorderColor || `var(--dls-success-color-100)`};

      .cta_text {
        text-align: center;
        color: ${({ctaTextColor}) =>
          ctaTextColor || `var(--dls-success-color-100)`};
      }
    }
  }
`
