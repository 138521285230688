import styled from 'styled-components'

export const StyledRatingStarSelector = styled.div<{gap: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({gap}) => gap};
  .star {
    cursor: pointer;
  }
`
