import styled from 'styled-components'

const StyledContainer = styled.div`
  width: 100%;
  .label {
    color: var(--brand-light-secondary-text-color);
  }
  .flexRowGap {
    display: flex;
    gap: var(--dls-size-16);
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: var(--dls-size-4);
  .optionLabel {
    color: var(--brand-light-secondary-text-color);
  }
`

export {StyledContainer, OptionContainer}
